import React, { useEffect, useState, useRef } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
import LeaveBalanceModal from "../../CommonModals/LeaveBalanceModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { Button } from 'primereact/button';
import ConfirmationModal from "../../CommonModals/ConfirmationModal";
// config file

const Employees = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [rolesList, setRolesList] = useState([]);
  const [gotRoles, setgotRoles] = useState(false);
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);
  const [isOpenLeaveBalanceModal, setIsOpenLeaveBalanceModal] = useState(false)
  const [leaveData, setLeaveData] = useState({})
  const [enableDisableTimesheet, setEnableDisableTimesheet] = useState(false)
  const [isOpenEnableDisableTimesheetModal, setIsOpenEnableDisableTimesheetModal] = useState(false)
  const [saveRowData, setSaveRowData] = useState({})

  let loginRole = JSON.parse(localStorage.getItem('loginCredentials'))

  let dataTableRef = useRef(null);
  const params = useParams();
  useEffect(() => {
    getRoleListFromServer();
    let screenPermissions = RolePermissions.screenPermissions("Employees");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
  }, []);

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
      storeData.settingsData &&
      storeData.settingsData.settings &&
      storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };
  // componentDidMount = () => {
  //   //Screen permisions value can be edit,view, no view
  //   let screenPermissions = RolePermissions.screenPermissions('Test');
  //   if (screenPermissions) {
  //     this.setState({
  //       rolePermission: screenPermissions
  //     })
  //   }
  //   // this.getRoleListFromServer()
  // }
  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };
  const getRoleListFromServer = async () => {
    let filterCriteria = {},
      url;
    filterCriteria.direction = "asc";
    url = `roles?filter=${JSON.stringify(filterCriteria)}`;
    fetchMethodRequest("GET", url)
      .then(async (res) => {
        if (res && res.roles && res.roles.length > 0) {
          let rolesList = [];
          for (let obj of res.roles) {
            if (obj.role) rolesList.push({ value: obj.role, label: obj.role });
          }
          // await this.setState({ rolesList: rolesList,gotRoles:true });
          setRolesList(rolesList);
          setgotRoles(true);
        }
      })
      .catch((err) => {
        setgotRoles(true);
        return err;
      });
  };

  const customFun = (rowData) => {
    let arr = [];

    if (rowData && rowData.status) {
      if (rowData.status === "Pending") {
        arr.push('edit', 'delete');
      } else if (rowData.status === 'Approved' || rowData.status === "Rejected") {
        if (loginRole.roleType === 'Admin') {
          arr.push('delete');
        }
      }
    }
    return arr
  }

  const getTableFields = () => {

    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "S.No",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "name",
        type: "text",
        placeholder: "Name",
        label: "Name",
        header: "Name",
        width: 110,
        sortable: true,
        filter: true,
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        fieldType: "Link",
        textAlign: "Center",
        capitalizeTableText: true,
        displayinregisterForm: "true",
        disabled: true,
        globalSearchField: "true",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        field: "displayName",
        addFormOrder: 10,
        editFormOrder: 1,
        fieldName: "name",
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#f17038",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "email",
        fieldName: "email",
        type: "email",
        placeholder: "Email",
        value: "",
        label: "Email",
        id: "email",
        width: 150,
        displayinaddForm: "true",
        displayineditForm: "false",
        displayinlist: "true",
        controllerName: null,
        sortable: true,
        filter: true,
        id: "email",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "true",
        disabled: true,
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "email",
        header: "Email",
        addFormOrder: 4,
        editFormOrder: 2,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "role",
        fieldName: "role",
        type: "dropDown",
        placeholder: "Role",
        value: "",
        label: "Role",
        width: 110,
        addFormOrder: 3,
        editFormOrder: 4,
        header: "Role",
        derivedValue: "role=role=role=role=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "role",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [],
        fieldType: "dropDown",
        filterElement: rolesList,
        // dependent: [],
        textAlign: "center",
        show: true,
        disabled: true,
        field: "role",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true
      },
      {
        name: "reportingToName",
        fieldName: "reportingToName",
        type: "relateAutoComplete",
        placeholder: "Reporting To",
        value: "",
        label: "Reporting To",
        width: 150,
        addFormOrder: 7,
        editFormOrder: 6,
        header: "Reporting To",
        derivedValue:
          "reportingTo=reportingTo=reportingTo=reportingTo=reportingTo=reportingTo=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "reportingToName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "false",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: 1001,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        searchField: "displayName",
        fieldType: "relateAutoComplete",
        displayFields: [],
        controllerName: "employee",
        searchApi: "employees",
        textAlign: "center",
        show: true,
        field: "reportingTo",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        fieldName: "created",
        type: "date",
        placeholder: "Created",
        value: "",
        label: "Created",
        width: 90,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Created",
        derivedValue: "created=created=created=created=undefined",
        capitalizeTableText: "",
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "created",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        fieldName: "updated",
        type: "date",
        placeholder: "Updated",
        value: "",
        label: "Updated",
        width: 90,
        addFormOrder: 11,
        editFormOrder: 11,
        header: "Updated",
        derivedValue: "updated=updated=updated=updated=undefined",
        capitalizeTableText: "",
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        disabled: true,
        show: true,
        field: "updated",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "DOB",
        fieldName: "DOB",
        type: "date",
        placeholder: "Date Of Birth",
        value: "",
        label: "Date Of Birth",
        width: "130px",
        addFormOrder: 11,
        editFormOrder: 12,
        header: "Date Of Birth",
        derivedValue:
          "DOB=DOB=DOB=DOB=DOB=DOB=DOB=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "DOB",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "dateOfBirth",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "DateOfJoin",
        fieldName: "DateOfJoin",
        type: "date",
        placeholder: "Date Of Join",
        value: "",
        label: "Date Of Join",
        width: "130px",
        addFormOrder: 12,
        editFormOrder: 13,
        header: "Date Of Join",
        derivedValue:
          "DateOfJoin=DateOfJoin=DateOfJoin=DateOfJoin=DateOfJoin=joinDate=DateOfJoin=DateOfJoin=DateOfJoin=DateOfJoin=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "DateOfJoin",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "joinDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "companyEmail",
        fieldName: "companyEmail",
        type: "email",
        placeholder: "Company Email",
        value: "",
        label: "Company Email",
        width: "150px",
        addFormOrder: 13,
        editFormOrder: 15,
        header: "Company Email",
        derivedValue:
          "CompanyEmail=CompanyEmail=CompanyEmail=CompanyEmail=CompanyEmail=CompanyEmail=CompanyEmail=CompanyEmail=CompanyEmail=CompanyEmail=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "CompanyEmail",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "companyEmail",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "AadharNo",
        fieldName: "AadharNo",
        type: "text",
        placeholder: "Aadhar No",
        value: "",
        label: "Aadhar No",
        width: "130px",
        addFormOrder: 9,
        editFormOrder: 10,
        header: "Aadhar No",
        derivedValue:
          "AadharNo=AadharNo=AadharNo=AadharNo=AadharNo=AadharNo=AadharNo=AadharNo=AadharNo=AadharNo=AadharNo=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "AadharNo",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        validationRequired: true,
        regexPattern: "^[0-9]{0,12}$",
        regexData: {
          showMinField: true,
          minLengthRequired: false,
          minLengthText: null,
          showMaxField: true,
          maxLengthRequired: true,
          maxLengthText: "12",
          alphabetsRequired: false,
          showAlphaField: false,
          numbersRequired: true,
          showNumField: true,
          specialCharRequired: false,
          showSCharField: false,
          specialCharText: null,
        },
        textAlign: "center",
        show: true,
        field: "aadharcardNumber",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "EmployeeId",
        fieldName: "EmployeeId",
        type: "uniqueField",
        placeholder: "Employee Id",
        value: "",
        label: "Employee Id",
        width: "120px",
        addFormOrder: 15,
        editFormOrder: 18,
        header: "Employee Id",
        derivedValue:
          "EmployeeId=EmployeeId=EmployeeId=EmployeeId=EmployeeId=EmployeeId=EmployeeId=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "EmployeeId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "employeeId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "biometricId",
        fieldName: "biometricId",
        type: "uniqueField",
        placeholder: "Biometric Id",
        value: "",
        label: "Biometric Id",
        width: "120px",
        addFormOrder: 16,
        editFormOrder: 19,
        header: "Biometric Id",
        derivedValue:
          "biometricId=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "biometricId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "biometricId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "PhoneNumber",
        fieldName: "PhoneNumber",
        type: "text",
        placeholder: "Phone Number",
        value: "",
        label: "Phone Number",
        width: "130px",
        addFormOrder: 17,
        editFormOrder: 20,
        header: "Phone Number",
        derivedValue:
          "PhoneNumber=PhoneNumber=PhoneNumber=PhoneNumber=PhoneNumber=PhoneNumber=PhoneNumber=PhoneNumber=PhoneNumber=PhoneNumber=PhoneNumber=PhoneNumber=PhoneNumber=PhoneNumber=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "PhoneNumber",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        validationRequired: true,
        regexPattern: "^[0-9]{0,10}$",
        regexData: {
          showMinField: true,
          minLengthRequired: false,
          minLengthText: null,
          showMaxField: true,
          maxLengthRequired: true,
          maxLengthText: "10",
          alphabetsRequired: false,
          showAlphaField: false,
          numbersRequired: true,
          showNumField: true,
          specialCharRequired: false,
          showSCharField: false,
          specialCharText: null,
        },
        textAlign: "center",
        show: true,
        field: "phoneNumber",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "emergencyContact",
        fieldName: "emergencyContact",
        type: "text",
        placeholder: "Emergency Contact",
        value: "",
        label: "Emergency Contact",
        width: "130px",
        addFormOrder: 18,
        editFormOrder: 21,
        header: "Emergency Contact",
        derivedValue:
          "emergencyContact=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "emergencyContact",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        validationRequired: true,
        regexPattern: "^[0-9]{0,10}$",
        regexData: {
          showMinField: true,
          minLengthRequired: false,
          minLengthText: null,
          showMaxField: true,
          maxLengthRequired: true,
          maxLengthText: "10",
          alphabetsRequired: false,
          showAlphaField: false,
          numbersRequired: true,
          showNumField: true,
          specialCharRequired: false,
          showSCharField: false,
          specialCharText: null,
        },
        textAlign: "center",
        show: true,
        field: "emergencyContact",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "skypeId",
        fieldName: "skypeId",
        type: "uniqueField",
        placeholder: "Skype Id",
        value: "",
        label: "Skype Id",
        width: "120px",
        addFormOrder: 19,
        editFormOrder: 22,
        header: "Skype Id",
        derivedValue:
          "skypeId=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "skypeId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "skypeId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "bitbuketId",
        fieldName: "bitbuketId",
        type: "uniqueField",
        placeholder: "Bitbuket ID",
        value: "",
        label: "Bitbuket ID",
        width: "120px",
        addFormOrder: 20,
        editFormOrder: 23,
        header: "Bitbuket ID",
        derivedValue:
          "bitbuketId=bitbuketId=bitbuketId=bitbuketId=bitbuketId=BitBucketId=BitBucketId=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "bitbuketId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "bitbuketId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "qualification",
        fieldName: "qualification",
        type: "text",
        placeholder: "Qualification",
        value: "",
        label: "Qualification",
        width: "130px",
        addFormOrder: 21,
        editFormOrder: 24,
        header: "Qualification",
        derivedValue:
          "qualification=qualification=qualification=qualification=Qualification=Qualification=Qualification=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Qualification",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "qualification",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "document",
        fieldName: "document",
        type: "dropDown",
        placeholder: "Document",
        value: "",
        label: "Document",
        width: "130px",
        addFormOrder: 25,
        editFormOrder: 28,
        header: "document",
        derivedValue: "document=document=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "document",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        globalSearchField: "true",
        controllerId: null,
        options: [
          { label: '10th Certificate', value: '10th Certificate', color: "info" },
          { label: 'Inter', value: 'Inter', color: 'primary' },
          { label: 'Diploma', value: 'Diploma', color: "secondary" },
          { label: 'Degree', value: 'Degree', color: "warning" },
          { label: 'BE/B-Tech', value: 'BE/B-Tech', color: "success" },
          { label: 'MBA', value: 'MBA', color: 'success' },
          { label: 'ME/M-Tech', value: 'ME/M-Tech', color: "danger" },
          { label: 'MCA', value: 'MCA', color: 'dark' },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: '10th Certificate', value: '10th Certificate', color: "info" },
          { label: 'Inter', value: 'Inter', color: 'primary' },
          { label: 'Diploma', value: 'Diploma', color: "secondary" },
          { label: 'Degree', value: 'Degree', color: "warning" },
          { label: 'BE/B-Tech', value: 'BE/B-Tech', color: "success" },
          { label: 'MBA', value: 'MBA', color: 'success' },
          { label: 'ME/M-Tech', value: 'ME/M-Tech', color: "danger" },
          { label: 'MCA', value: 'MCA', color: 'dark' },
        ],
        // dependent: [],
        textAlign: "center",
        show: true,
        field: "document",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        isMultiSelect: true
      },
      {
        name: "Consultancy",
        fieldName: "Consultancy",
        type: "text",
        placeholder: "Consultancy",
        value: "",
        label: "Consultancy",
        width: "130px",
        addFormOrder: 22,
        editFormOrder: 25,
        header: "Consultancy",
        derivedValue:
          "consultancy=consultancy=consultancy=consultancy=Consultancy=Consultancy=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Consultancy",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "consultancy",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "PreviousExperience",
        fieldName: "PreviousExperience",
        type: "text",
        placeholder: "Previous Experience",
        value: "",
        label: "Previous Experience",
        width: "130px",
        addFormOrder: 23,
        editFormOrder: 26,
        header: "Previous Experience",
        derivedValue:
          "previousExperience=previousExperience=previousExperience=PreviousExperience=PreviousExperience=PreviousExperience=PreviousExperience=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "PreviousExperience",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "previousExperience",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "previousCompany",
        fieldName: "previousCompany",
        type: "text",
        placeholder: "Previous Company",
        value: "",
        label: "Previous Company",
        width: "130px",
        addFormOrder: 24,
        editFormOrder: 27,
        header: "Previous Company",
        derivedValue:
          "previousCompany=previousCompany=previousCompaney=previousCompaney=PreviousCompany=PreviousCompany=PreviousCompany=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "previousCompany",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "previousCompany",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "EmploymentType",
        fieldName: "employmentType",
        type: "dropDown",
        placeholder: "Employment Type",
        value: "",
        label: "Employment Type",
        width: "130px",
        addFormOrder: 25,
        editFormOrder: 28,
        header: "Employment Type",
        derivedValue:
          "employmentType=employmentType=employmentType=EmploymentType=EmploymentType=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "EmploymentType",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          { label: "Permanent", value: "Permanent", color: "success" },
          { label: "Contract", value: "Contract", color: "primary" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "Permanent", value: "Permanent", color: "primary" },
          { label: "Contract", value: "Contract", color: "primary" },
        ],
        // dependent: [],
        textAlign: "center",
        show: true,
        field: "employmentType",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Gender",
        fieldName: "Gender",
        type: "radio",
        placeholder: "Gender",
        value: "Female",
        label: "Gender",
        width: "120px",
        addFormOrder: 26,
        editFormOrder: 31,
        header: "Gender",
        derivedValue: "gender=gender=gender=Gender=Gender=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Gender",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "Female", value: "Female" },
          { label: "Male", value: "Male" },
        ],
        fieldType: "radio",
        filterElement: [
          { label: "Female", value: "Female" },
          { label: "Male", value: "Male" },
        ],
        textAlign: "center",
        show: true,
        field: "gender",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "TemporaryAddress",
        fieldName: "TemporaryAddress",
        type: "textarea",
        placeholder: "Temporary Address",
        value: "",
        label: "Temporary Address",
        width: "200px",
        addFormOrder: 27,
        editFormOrder: 32,
        header: "Temporary Address",
        derivedValue:
          "temporaryAddress=temporaryAddress=temporaryAddress=temporaryAddress=TemporaryAddress=TemporaryAddress=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "TemporaryAddress",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "temporaryAddress",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "PermanentAddress",
        fieldName: "PermanentAddress",
        type: "textarea",
        placeholder: "Permanent Address",
        value: "",
        label: "Permanent Address",
        width: "200px",
        addFormOrder: 28,
        editFormOrder: 33,
        header: "Permanent Address",
        derivedValue:
          "permanentAddress=permanentAddress=permanentAddress=permanentAddress=PermanentAddress=PermanentAddress=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "PermanentAddress",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        style: { width: '250px', textOverflow: "ellipsis" },
        show: true,
        field: "permanentAddress",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "BankAccountNo",
        fieldName: "BankAccountNo",
        type: "text",
        placeholder: "Bank Account No",
        value: "",
        label: "Bank Account No",
        width: "130px",
        addFormOrder: 29,
        editFormOrder: 34,
        header: "Bank Account No",
        derivedValue:
          "accountNo=accountNo=accountNo=BankAccountNo=BankAccountNo=BankAccountNo=BankAccountNo=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "BankAccountNo",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        validationRequired: true,
        regexPattern: "^.{0,10000}$",
        regexData: {
          showMinField: true,
          minLengthRequired: false,
          minLengthText: null,
          showMaxField: true,
          maxLengthRequired: false,
          maxLengthText: null,
          alphabetsRequired: false,
          showAlphaField: false,
          numbersRequired: false,
          showNumField: false,
          specialCharRequired: false,
          showSCharField: false,
          specialCharText: null,
        },
        textAlign: "center",
        show: true,
        field: "accountNo",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "UANNumber",
        fieldName: "UANNumber",
        type: "uniqueField",
        placeholder: "UAN Number",
        value: "",
        label: "UAN Number",
        width: "120px",
        addFormOrder: 30,
        editFormOrder: 35,
        header: "UAN Number",
        derivedValue: "uanNumber=uanNumber=UANNumber=UANNumber=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "UANNumber",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "uanNumber",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "IFSCCode",
        fieldName: "IFSCCode",
        type: "uniqueField",
        placeholder: "IFSC Code",
        value: "",
        label: "IFSC Code",
        width: "120px",
        addFormOrder: 31,
        editFormOrder: 36,
        header: "IFSC Code",
        derivedValue:
          "ifscCode=ifscCode=ifscCode=ifscCode=IFSCCode=IFSCCode=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "IFSCCode",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "ifscCode",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "employeeDocumentDriveLink",
        fieldName: "employeeDocumentDriveLink",
        type: "textarea",
        placeholder: "Employee Document Drive Link",
        value: "",
        label: "Employee Document Drive Link",
        width: "200px",
        addFormOrder: 33,
        editFormOrder: 37,
        header: "Employee Document Drive Link",
        derivedValue:
          "employeeDocumentDriveLink=employeeDocumentDriveLink=employeeDocumentDriveLink=employeeDocumentDriveLink=employeeDocumentDriveLink=employeeDocumentDriveLink=employeeDocumentDriveLinks=EmployeeDocumentDriveLink=EmployeeDocumentDriveLink=EmployeeDocumentDriveLink=EmployeeDocumentDriveLink=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "EmployeeDocumentDriveLink",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: true,
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        fieldType: "WebSite",
        field: "employeeDocumentDriveLink",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "IdCardReceived",
        fieldName: "IdCardReceived",
        type: "radio",
        placeholder: "Id Card Received ?",
        value: "",
        label: "Id Card Received ?",
        width: "120px",
        addFormOrder: 35,
        editFormOrder: 38,
        header: "Id Card Received ?",
        derivedValue:
          "idCardReceived=idCardReceived=idCardReceived=idCardReceived=IdCardRecieved=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "IdCardReceived",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" },
        ],
        fieldType: "radio",
        filterElement: [
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" },
        ],
        textAlign: "center",
        show: true,
        field: "idCardReceived",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Orientation_date",
        fieldName: "Orientation_date",
        type: "date",
        placeholder: "Orientation Date",
        value: "",
        label: "Orientation Date",
        width: "130px",
        addFormOrder: 32,
        editFormOrder: 36,
        header: "Orientation Date",
        derivedValue:
          "orientationDate=orientationDate=orientationDate=Orientation_date=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Orientation_date",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "orientationDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Termination_date",
        fieldName: "Termination_date",
        type: "date",
        placeholder: "Termination Date",
        value: "",
        label: "Termination Date",
        width: "130px",
        addFormOrder: 34,
        editFormOrder: 37,
        header: "Termination Date",
        derivedValue:
          "terminationDate=terminationDate=terminationDate=Termination_date=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Termination_date",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "terminationDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Bank_name",
        fieldName: "Bank_name",
        type: "text",
        placeholder: "Bank Name",
        value: "",
        label: "Bank Name",
        width: "130px",
        addFormOrder: 37,
        editFormOrder: 41,
        header: "Bank Name",
        derivedValue: "bankName=bankName=bankName=bankName=Bank_name=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Bank_name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "bankName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "BranchName",
        fieldName: "BranchName",
        type: "text",
        placeholder: "Branch Name",
        value: "",
        label: "Branch Name",
        width: "130px",
        addFormOrder: 38,
        editFormOrder: 42,
        header: "Branch Name",
        derivedValue: "branch=branch=branch=BranchName=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "BranchName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "branch",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "image",
        fieldName: "image",
        type: "fileUpload",
        placeholder: "Attach File",
        value: "",
        label: "Attach File",
        width: "120px",
        addFormOrder: 39,
        editFormOrder: 43,
        header: "Attach File",
        derivedValue:
          "attatchFile=attatchFile=image=image=image=file=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "image",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        fieldType: "fileUpload",
        fileType: "img/video",
        maxFileSize: 5,
        isMultipleRequired: true,
        imagePath: "employee",
        textAlign: "center",
        show: true,
        field: "image",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "totalLeaves",
        fieldName: "totalLeaves",
        type: "number",
        placeholder: "Total Leaves",
        value: "",
        label: "Total Leaves",
        width: "120px",
        addFormOrder: 48,
        editFormOrder: 48,
        header: "Total Leaves",
        derivedValue:
          "totalLeaves=totalLeaves=totalLeaves=totalLeaves=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "totalLeaves",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "totalLeaves",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Status",
        type: "dropDown",
        placeholder: "Status",
        value: "",
        label: "Status",
        width: "130px",
        addFormOrder: 40,
        editFormOrder: 44,
        header: "Status",
        derivedValue: "status=status=status=Status=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Status",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: true,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          { label: "Active", value: "Active", color: "success" },
          { label: "Pending", value: "Pending", color: "danger" },
          { label: "InActive", value: "InActive", color: "warning" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "Active", value: "Active", color: "success" },
          { label: "Pending", value: "Pending", color: "danger" },
          { label: "InActive", value: "InActive", color: "warning" },
        ],
        // dependent: [],
        textAlign: "center",
        show: true,
        field: "status",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "pancardNumber",
        fieldName: "pancardNumber",
        type: "text",
        placeholder: "PAN",
        value: "",
        label: "PAN",
        width: "130px",
        addFormOrder: 43,
        editFormOrder: 43,
        header: "PAN",
        derivedValue:
          "pancardNumber=pancardNumber=pancardNumber=pancardNumber=pan=pan=pan=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "pancardNumber",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        validationRequired: true,
        regexPattern: "^[a-zA-Z0-9]{0,10}$",
        regexData: {
          showMinField: false,
          minLengthRequired: false,
          minLengthText: null,
          showMaxField: true,
          maxLengthRequired: true,
          maxLengthText: "10",
          alphabetsRequired: true,
          showAlphaField: true,
          numbersRequired: true,
          showNumField: true,
          specialCharRequired: false,
          showSCharField: false,
          specialCharText: null,
        },
        textAlign: "center",
        show: true,
        field: "pancardNumber",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "fatherName",
        fieldName: "fatherName",
        type: "text",
        placeholder: "Father's Name",
        value: "",
        label: "Father's Name",
        width: "130px",
        addFormOrder: 23,
        editFormOrder: 26,
        header: "Father's Name",
        derivedValue:
          "fatherName=fatherName=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "fatherName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "fatherName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "motherName",
        fieldName: "motherName",
        type: "text",
        placeholder: "Mother's Name",
        value: "",
        label: "Mother's Name",
        width: "130px",
        addFormOrder: 23,
        editFormOrder: 26,
        header: "Mother's Name",
        derivedValue:
          "motherName=motherName=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "motherName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "motherName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "spouseName",
        fieldName: "spouseName",
        type: "text",
        placeholder: "Spouse's Name",
        value: "",
        label: "Spouse's Name",
        width: "130px",
        addFormOrder: 23,
        editFormOrder: 26,
        header: "Spouse's Name",
        derivedValue:
          "spouseName=spouseName=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "spouseName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "spouseName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "true",
        displayineditForm: "true",
        globalSearchField: "false",
        isFieldRequired: "false",
        mobile: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 130,
        fieldType: "EnableTimesheet",
        field: "Enable Timesheet",
        header: "Enable Timesheet",
        label: "Enable Timesheet",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        mobile: true,
        tDisplay: true,
      },
    ];
    return data;
  };

  const getFormFields = () => {
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    return [
      {
        name: "email",
        fieldName: "email",
        type: "email",
        placeholder: "Email",
        value: "",
        label: "Email",
        width: 150,
        addFormOrder: 5,
        editFormOrder: 5,
        derivedValue: "email=email=email=email=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "email",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        disabled: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "role",
        fieldName: "role",
        type: "dropDown",
        placeholder: "Role",
        value: "",
        label: "Role",
        width: 110,
        addFormOrder: 21,
        editFormOrder: 22,
        derivedValue: "role=role=role=role=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "role",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [],
        fieldType: "dropDown",
        filterElement: [],
        // dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "reportingTo",
        fieldName: "reportingTo",
        type: "relateAutoComplete",
        placeholder: "Reporting To",
        value: "",
        label: "Reporting To",
        width: 110,
        addFormOrder: 22,
        editFormOrder: 23,
        derivedValue:
          "reportingTo=reportingTo=reportingTo=reportingTo=reportingTo=reportingTo=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "reportingTo",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: 1001,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        searchField: "displayName",
        fieldType: "relateAutoComplete",
        displayFields: [],
        controllerName: "employee",
        searchApi: "employees",
        filterCriteria: { key: 'status', value: 'Active', type: 'eq' },
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "hrReportingTo",
        type: "relateAutoComplete",
        placeholder: "ReportingTo HR",
        label: "Reporting To HR",
        derivedValue: "reportingTo=undefined",
        actions: [],
        actionsNumber: [],
        id: "reportingTo",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: "employee",
        searchField: "displayName",
        fieldType: "relateAutoComplete",
        controllerId: 1001,
        searchApi: "employees",
        filterCriteria: { key: 'status', value: 'Active', type: 'eq' },
        width: 110,
        show: true,
        required: true,
        disabled: true,
        addFormOrder: 22,
        editFormOrder: 23,
        fieldName: "hrReportingTo",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "firstName",
        fieldName: "firstName",
        type: "text",
        placeholder: "First Name",
        value: "",
        label: "First Name",
        width: "130px",
        addFormOrder: 1,
        editFormOrder: 1,
        derivedValue:
          "firstName=firstName=firstName=firstName=firstName=firstName=FirstName=FirstName=FirstName=FirstName=FirstName=FirstName=FirstName=FirstName=FirstName=undefined",
        capitalizeTableText: true,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "FirstName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        validationRequired: true,
        regexPattern: "^[a-zA-Z ]",
        regexData: {
          showMinField: false,
          minLengthRequired: false,
          minLengthText: null,
          showMaxField: false,
          maxLengthRequired: false,
          maxLengthText: null,
          alphabetsRequired: true,
          showAlphaField: true,
          numbersRequired: false,
          showNumField: false,
          specialCharRequired: false,
          showSCharField: false,
          specialCharText: null,
        },
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#f17038",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "lastName",
        fieldName: "lastName",
        type: "text",
        placeholder: "Last Name",
        value: "",
        label: "Last Name",
        width: "130px",
        addFormOrder: 2,
        editFormOrder: 2,
        derivedValue:
          "lastName=lastName=lastName=lastName=LastName=LastName=LastName=LastName=undefined",
        capitalizeTableText: true,
        sortable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "LastName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        validationRequired: true,
        regexPattern: "^[a-zA-Z ]",
        regexData: {
          showMinField: false,
          minLengthRequired: false,
          minLengthText: null,
          showMaxField: false,
          maxLengthRequired: false,
          maxLengthText: null,
          alphabetsRequired: true,
          showAlphaField: true,
          numbersRequired: false,
          showNumField: false,
          specialCharRequired: false,
          showSCharField: false,
          specialCharText: null,
        },
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "dateOfBirth",
        fieldName: "dateOfBirth",
        type: "date",
        placeholder: "Date Of Birth",
        value: "",
        label: "Date Of Birth",
        width: "130px",
        addFormOrder: 3,
        editFormOrder: 3,
        derivedValue:
          "dateOfBirth=dateOfBirth=dateOfBirth=DOB=DOB=DOB=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "dateOfBirth",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "joinDate",
        fieldName: "joinDate",
        type: "date",
        placeholder: "Date Of Join",
        value: "",
        label: "Date Of Join",
        width: "130px",
        addFormOrder: 4,
        editFormOrder: 4,
        derivedValue:
          "joinDate=joinDate=joinDate=joinDate=joinDate=DateOfJoin=DateOfJoin=DateOfJoin=DateOfJoin=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "DateOfJoin",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "companyEmail",
        fieldName: "companyEmail",
        type: "email",
        placeholder: "Company Email",
        value: "",
        label: "Company Email",
        width: "150px",
        addFormOrder: 6,
        editFormOrder: 6,
        derivedValue:
          "CompanyEmail=CompanyEmail=CompanyEmail=CompanyEmail=CompanyEmail=CompanyEmail=CompanyEmail=CompanyEmail=CompanyEmail=CompanyEmail=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "CompanyEmail",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        isEditFieldDisable: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "pancardNumber",
        fieldName: "pancardNumber",
        type: "uniqueField",
        placeholder: "PAN",
        value: "",
        label: "PAN",
        width: "130px",
        addFormOrder: 7,
        editFormOrder: 7,
        derivedValue:
          "pancardNumber=pancardNumber=pancardNumber=pancardNumber=pan=pan=pan=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "PAN",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        validationRequired: false,
        regexPattern: "^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "aadharcardNumber",
        fieldName: "aadharcardNumber",
        type: "text",
        placeholder: "Aadhar No",
        value: "",
        label: "Aadhar No",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        derivedValue:
          "aadharcardNumber=aadharcardNumber=aadharcardNumber=aadharcardNumber=AadharNo=AadharNo=AadharNo=AadharNo=AadharNo=AadharNo=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "aadharcardNumber",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        validationRequired: true,
        regexPattern: "^[0-9]{12,12}$",
        regexData: {
          showMinField: true,
          minLengthRequired: true,
          minLengthText: "12",
          showMaxField: true,
          maxLengthRequired: true,
          maxLengthText: "12",
          alphabetsRequired: false,
          showAlphaField: false,
          numbersRequired: true,
          showNumField: true,
          specialCharRequired: false,
          showSCharField: false,
          specialCharText: null,
        },
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "employeeId",
        fieldName: "employeeId",
        type: "uniqueField",
        placeholder: "Employee Id",
        value: "",
        label: "Employee Id",
        width: "120px",
        addFormOrder: 9,
        editFormOrder: 9,
        derivedValue:
          "employeeId=employeeId=employeeId=employeeId=EmployeeId=EmployeeId=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "employeeId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "biometricId",
        fieldName: "biometricId",
        type: "uniqueField",
        placeholder: "Biometric Id",
        value: "",
        label: "Biometric Id",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        derivedValue:
          "biometricId=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "biometricId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "phoneNumber",
        fieldName: "PhoneNumber",
        type: "text",
        placeholder: "Phone Number",
        value: "",
        label: "Phone Number",
        width: "130px",
        addFormOrder: 17,
        editFormOrder: 20,
        derivedValue:
          "PhoneNumber=PhoneNumber=PhoneNumber=PhoneNumber=PhoneNumber=PhoneNumber=PhoneNumber=PhoneNumber=PhoneNumber=PhoneNumber=PhoneNumber=PhoneNumber=PhoneNumber=PhoneNumber=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "PhoneNumber",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        validationRequired: true,
        regexPattern: "^[0-9]{10,10}$",
        regexData: {
          showMinField: true,
          minLengthRequired: true,
          minLengthText: "10",
          showMaxField: true,
          maxLengthRequired: true,
          maxLengthText: "10",
          alphabetsRequired: false,
          showAlphaField: false,
          numbersRequired: true,
          showNumField: true,
          specialCharRequired: false,
          showSCharField: false,
          specialCharText: null,
        },
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "emergencyContact",
        fieldName: "emergencyContact",
        type: "text",
        placeholder: "Emergency Contact",
        value: "",
        label: "Emergency Contact",
        width: "130px",
        addFormOrder: 11,
        editFormOrder: 11,
        derivedValue:
          "emergencyContact=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "emergencyContact",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        validationRequired: true,
        regexPattern: "^[0-9]{10,10}$",
        regexData: {
          showMinField: true,
          minLengthRequired: true,
          minLengthText: "10",
          showMaxField: true,
          maxLengthRequired: true,
          maxLengthText: "10",
          alphabetsRequired: false,
          showAlphaField: false,
          numbersRequired: true,
          showNumField: true,
          specialCharRequired: false,
          showSCharField: false,
          specialCharText: null,
        },
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "skypeId",
        fieldName: "skypeId",
        type: "uniqueField",
        placeholder: "Skype Id",
        value: "",
        label: "Skype Id",
        width: "120px",
        addFormOrder: 12,
        editFormOrder: 12,
        derivedValue:
          "skypeId=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "skypeId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "bitbuketId",
        fieldName: "bitbuketId",
        type: "uniqueField",
        placeholder: "Bitbuket ID",
        value: "",
        label: "Bitbuket ID",
        width: "120px",
        addFormOrder: 14,
        editFormOrder: 14,
        derivedValue:
          "bitbuketId=bitbuketId=bitbuketId=bitbuketId=bitbuketId=BitBucketId=BitBucketId=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "BitBucketId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "qualification",
        fieldName: "qualification",
        type: "text",
        placeholder: "Qualification",
        value: "",
        label: "Qualification",
        width: "130px",
        addFormOrder: 15,
        editFormOrder: 15,
        derivedValue:
          "qualification=qualification=qualification=qualification=Qualification=Qualification=Qualification=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Qualification",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "document",
        fieldName: "document",
        type: "dropDown",
        placeholder: "Document",
        value: "",
        label: "Document",
        width: "130px",
        addFormOrder: 45,
        editFormOrder: 45,
        derivedValue: "document=document=document=document=document=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "document",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          {
            label: "10th Certificate",
            value: "10th Certificate",
            color: "info",
          },
          { label: "Inter", value: "Inter", color: "primary" },
          { label: "Diploma", value: "Diploma", color: "secondary" },
          { label: "Degree", value: "Degree", color: "success" },
          { label: "BE/B-Tech", value: "BE/B-Tech", color: "warning" },
          { label: "MBA", value: "MBA", color: "danger" },
          { label: "ME/M-Tech", value: "ME/M-Tech", color: "dark" },
          { label: "MCA", value: "MCA", color: "danger" },
        ],
        fieldType: "dropDown",
        filterElement: [
          {
            label: "10th Certificate",
            value: "10th Certificate",
            color: "info",
          },
          { label: "Inter", value: "Inter", color: "primary" },
          { label: "Diploma", value: "Diploma", color: "secondary" },
          { label: "Degree", value: "Degree", color: "success" },
          { label: "BE/B-Tech", value: "BE/B-Tech", color: "warning" },
          { label: "MBA", value: "MBA", color: "danger" },
          { label: "ME/M-Tech", value: "ME/M-Tech", color: "dark" },
          { label: "MCA", value: "MCA", color: "danger" },
        ],
        // dependent: [],
        isMultiSelect:true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        isMultiSelect: true
      },
      {
        name: "consultancy",
        fieldName: "consultancy",
        type: "text",
        placeholder: "Consultancy",
        value: "",
        label: "Consultancy",
        width: "130px",
        addFormOrder: 16,
        editFormOrder: 16,
        derivedValue:
          "consultancy=consultancy=consultancy=consultancy=Consultancy=Consultancy=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Consultancy",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "previousExperience",
        fieldName: "previousExperience",
        type: "text",
        placeholder: "Previous Experience",
        value: "",
        label: "Previous Experience",
        width: "130px",
        addFormOrder: 17,
        editFormOrder: 17,
        derivedValue:
          "previousExperience=previousExperience=previousExperience=PreviousExperience=PreviousExperience=PreviousExperience=PreviousExperience=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "PreviousExperience",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "previousCompany",
        fieldName: "previousCompany",
        type: "text",
        placeholder: "Previous Company",
        value: "",
        label: "Previous Company",
        width: "130px",
        addFormOrder: 18,
        editFormOrder: 18,
        derivedValue:
          "previousCompany=previousCompany=previousCompaney=previousCompaney=PreviousCompany=PreviousCompany=PreviousCompany=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "previousCompany",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "employmentType",
        fieldName: "employmentType",
        type: "dropDown",
        placeholder: "Employment Type",
        value: "",
        label: "Employment Type",
        width: "130px",
        addFormOrder: 20,
        editFormOrder: 21,
        derivedValue:
          "employmentType=employmentType=employmentType=EmploymentType=EmploymentType=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "EmploymentType",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          { label: "Permanent", value: "Permanent", color: "info" },
          { label: "Contract", value: "Contract", color: "primary" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "Permanent", value: "Permanent", color: "primary" },
          { label: "Contract", value: "Contract", color: "primary" },
        ],
        // dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "gender",
        fieldName: "gender",
        type: "radio",
        placeholder: "Gender",
        value: "",
        label: "Gender",
        width: "120px",
        addFormOrder: 26,
        editFormOrder: 24,
        derivedValue: "gender=gender=gender=Gender=Gender=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Gender",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "Female", value: "Female" },
          { label: "Male", value: "Male" },
        ],
        fieldType: "radio",
        filterElement: [
          { label: "Female", value: "Female" },
          { label: "Male", value: "Male" },
        ],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "temporaryAddress",
        fieldName: "temporaryAddress",
        type: "textarea",
        placeholder: "Temporary Address",
        value: "",
        label: "Temporary Address",
        width: "200px",
        addFormOrder: 27,
        editFormOrder: 25,
        derivedValue:
          "temporaryAddress=temporaryAddress=temporaryAddress=temporaryAddress=TemporaryAddress=TemporaryAddress=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "TemporaryAddress",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "permanentAddress",
        fieldName: "permanentAddress",
        type: "textarea",
        placeholder: "Permanent Address",
        value: "",
        label: "Permanent Address",
        width: "200px",
        addFormOrder: 28,
        editFormOrder: 26,
        derivedValue:
          "permanentAddress=permanentAddress=permanentAddress=permanentAddress=PermanentAddress=PermanentAddress=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "PermanentAddress",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "accountNo",
        fieldName: "accountNo",
        type: "text",
        placeholder: "Bank Account No",
        value: "",
        label: "Bank Account No",
        width: "130px",
        addFormOrder: 31,
        editFormOrder: 34,
        derivedValue:
          "accountNo=accountNo=accountNo=BankAccountNo=BankAccountNo=BankAccountNo=BankAccountNo=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "BankAccountNo",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        validationRequired: true,
        regexPattern: "^.{0,10000}$",
        regexData: {
          showMinField: true,
          minLengthRequired: false,
          minLengthText: null,
          showMaxField: true,
          maxLengthRequired: false,
          maxLengthText: null,
          alphabetsRequired: false,
          showAlphaField: false,
          numbersRequired: false,
          showNumField: false,
          specialCharRequired: false,
          showSCharField: false,
          specialCharText: null,
        },
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "uanNumber",
        fieldName: "uanNumber",
        type: "uniqueField",
        placeholder: "UAN Number",
        value: "",
        label: "UAN Number",
        width: "120px",
        addFormOrder: 33,
        editFormOrder: 36,
        derivedValue: "uanNumber=uanNumber=UANNumber=UANNumber=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "UANNumber",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        validationRequired: false,
        regexPattern: "^[0-9]{12,12}$",
        regexData: {
          showMinField: true,
          minLengthRequired: true,
          minLengthText: "12",
          showMaxField: true,
          maxLengthRequired: true,
          maxLengthText: "12",
          alphabetsRequired: false,
          showAlphaField: false,
          numbersRequired: true,
        },
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "ifscCode",
        fieldName: "ifscCode",
        type: "uniqueField",
        placeholder: "IFSC Code",
        value: "",
        label: "IFSC Code",
        width: "120px",
        addFormOrder: 32,
        editFormOrder: 35,
        derivedValue:
          "ifscCode=ifscCode=ifscCode=ifscCode=IFSCCode=IFSCCode=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "IFSCCode",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "employeeDocumentDriveLink",
        fieldName: "employeeDocumentDriveLink",
        type: "textarea",
        placeholder: "Employee Document Drive Link",
        value: "",
        label: "Employee Document Drive Link",
        width: "200px",
        addFormOrder: 33,
        editFormOrder: 38,
        derivedValue:
          "employeeDocumentDriveLink=employeeDocumentDriveLink=employeeDocumentDriveLink=employeeDocumentDriveLink=employeeDocumentDriveLink=employeeDocumentDriveLink=employeeDocumentDriveLinks=EmployeeDocumentDriveLink=EmployeeDocumentDriveLink=EmployeeDocumentDriveLink=EmployeeDocumentDriveLink=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "employeeDocumentDriveLink",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: true,
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "idCardReceived",
        fieldName: "idCardReceived",
        type: "radio",
        placeholder: "Id Card Received ?",
        value: "",
        label: "Id Card Received ?",
        width: "120px",
        addFormOrder: 35,
        editFormOrder: 38,
        derivedValue:
          "idCardReceived=idCardReceived=idCardReceived=idCardReceived=IdCardRecieved=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "IdCardReceived",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" },
        ],
        fieldType: "radio",
        filterElement: [
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" },
        ],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "leavesAccruedBlocked",
        fieldName: "leavesAccruedBlocked",
        type: "radio",
        placeholder: "LeavesAccruedBlocked",
        value: "false",
        label: "Leaves Accrued Blocked",
        width: "120px",
        addFormOrder: 35,
        editFormOrder: 38,
        stringType: null,
        derivedValue:
          "leavesAccruedBlocked=leavesAccruedBlocked=leavesAccruedBlocked=leavesAccruedBlocked=leavesAccruedBlocked=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        id: "leavesAccruedBlocked",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        // options: [],
        fieldType: "radio",
        options: [
          { label: "Yes", value: "true" },
          { label: "No", value: "false" },
        ],
        filterElement: [
          { label: "Yes", value: "true" },
          { label: "No", value: "false" },
        ],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "orientationDate",
        fieldName: "orientationDate",
        type: "date",
        placeholder: "Orientation Date",
        value: "",
        label: "Orientation Date",
        width: "130px",
        addFormOrder: 34,
        editFormOrder: 36,
        derivedValue:
          "orientationDate=orientationDate=orientationDate=Orientation_date=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Orientation_date",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "terminationDate",
        fieldName: "terminationDate",
        type: "date",
        placeholder: "Termination Date",
        value: "",
        label: "Termination Date",
        width: "130px",
        addFormOrder: 34,
        editFormOrder: 36,
        derivedValue:
          "terminationDate=terminationDate=terminationDate=Termination_date=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Termination_date",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: false,
      },
      {
        name: "bankName",
        fieldName: "bankName",
        type: "text",
        placeholder: "Bank Name",
        value: "",
        label: "Bank Name",
        width: "130px",
        addFormOrder: 29,
        editFormOrder: 31,
        derivedValue: "bankName=bankName=bankName=bankName=Bank_name=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Bank_name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "branch",
        fieldName: "branch",
        type: "text",
        placeholder: "Branch Name",
        value: "",
        label: "Branch Name",
        width: "130px",
        addFormOrder: 30,
        editFormOrder: 32,
        derivedValue: "branch=branch=branch=BranchName=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "BranchName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "status",
        fieldName: "status",
        type: "dropDown",
        placeholder: "Status",
        value: "",
        label: "Status",
        width: "130px",
        addFormOrder: 40,
        editFormOrder: 42,
        derivedValue: "status=status=status=Status=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Status",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: true,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          { label: "Active", value: "Active", color: "success" },
          { label: "Pending", value: "Pending", color: "danger" },
          { label: "InActive", value: "InActive", color: "warning" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "Active", value: "Active", color: "success" },
          { label: "Pending", value: "Pending", color: "danger" },
          { label: "InActive", value: "InActive", color: "warning" },
        ],
        // dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: false,
      },
      {
        name: "image",
        fieldName: "image",
        type: "fileUpload",
        placeholder: "Attach File",
        value: "",
        label: "Attach File",
        width: "120px",
        addFormOrder: 39,
        editFormOrder: 43,
        derivedValue:
          "attatchFile=attatchFile=image=image=image=file=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "image",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        fieldType: "fileUpload",
        fileType: "img/video",
        maxFileSize: 30,
        isMultipleRequired: true,
        imagePath: "employee",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "timesheetAutoApproved",
        type: "checkbox",
        placeholder: "Timesheet Approval",
        label: "Auto Timesheet Approval",
        width: "120px",
        addFormOrder: 40,
        editFormOrder: 44,
        derivedValue: "timesheetAutoApproved=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "timesheetAutoApproved",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        fieldType:"checkbox",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "fatherName",
        fieldName: "fatherName",
        type: "text",
        placeholder: "Father's Name",
        value: "",
        label: "Father's Name",
        width: "130px",
        addFormOrder: 6,
        editFormOrder: 6,
        derivedValue: "fatherName=fatherName=fatherName=fatherName=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "fatherName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "motherName",
        fieldName: "motherName",
        type: "text",
        placeholder: "Mother's Name",
        value: "",
        label: "Mother's Name",
        width: "130px",
        addFormOrder: 6,
        editFormOrder: 6,
        derivedValue: "motherName=motherName=motherName=motherName=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "motherName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "spouseName",
        fieldName: "spouseName",
        type: "text",
        placeholder: "Spouse's Name",
        value: "",
        label: "Spouse's Name",
        width: "130px",
        addFormOrder: 6,
        editFormOrder: 6,
        derivedValue: "spouseName=spouseName=spouseName=spouseName=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "spouseName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
    ];
  };

  const submit = async (item) => {
    setIsOpenFormModal(true);
    // await this.formModalRef.getRowData(item, 'edit');
    console.log("Submit Button in sode          ");
  };
  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "S.No",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "name",
        type: "text",
        placeholder: "Name",
        label: "Name",
        header: "Name",
        width: 110,
        sortable: true,
        filter: true,
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        fieldType: "Link",
        textAlign: "Center",
        capitalizeTableText: true,
        displayinregisterForm: "true",
        disabled: true,
        globalSearchField: "true",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        field: "displayName",
        addFormOrder: 10,
        editFormOrder: 1,
        fieldName: "name",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "email",
        fieldName: "email",
        type: "email",
        placeholder: "Email",
        value: "",
        label: "Email",
        id: "email",
        width: 150,
        displayinaddForm: "true",
        displayineditForm: "false",
        displayinlist: "true",
        controllerName: null,
        sortable: true,
        filter: true,
        id: "email",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "true",
        disabled: true,
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "email",
        header: "Email",
        addFormOrder: 4,
        editFormOrder: 2,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "role",
        fieldName: "role",
        type: "dropDown",
        placeholder: "Role",
        value: "",
        label: "Role",
        width: 110,
        addFormOrder: 3,
        editFormOrder: 4,
        header: "Role",
        derivedValue: "role=role=role=role=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "role",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [],
        fieldType: "dropDown",
        filterElement: rolesList,
        // dependent: [],
        textAlign: "center",
        show: true,
        disabled: true,
        field: "role",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true
      },
      {
        name: "reportingToName",
        fieldName: "reportingToName",
        type: "relateAutoComplete",
        placeholder: "Reporting To",
        value: "",
        label: "Reporting To",
        width: 150,
        addFormOrder: 7,
        editFormOrder: 6,
        header: "Reporting To",
        derivedValue:
          "reportingTo=reportingTo=reportingTo=reportingTo=reportingTo=reportingTo=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "reportingToName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "false",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: 1001,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        searchField: "displayName",
        fieldType: "relateAutoComplete",
        displayFields: [],
        controllerName: "employee",
        searchApi: "employees",
        textAlign: "center",
        show: true,
        field: "reportingTo",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        fieldName: "created",
        type: "date",
        placeholder: "Created",
        value: "",
        label: "Created",
        width: 90,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Created",
        derivedValue: "created=created=created=created=undefined",
        capitalizeTableText: "",
        sortable: "",
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "created",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        fieldName: "updated",
        type: "date",
        placeholder: "Updated",
        value: "",
        label: "Updated",
        width: 90,
        addFormOrder: 11,
        editFormOrder: 11,
        header: "Updated",
        derivedValue: "updated=updated=updated=updated=undefined",
        capitalizeTableText: "",
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        disabled: true,
        show: true,
        field: "updated",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "DOB",
        fieldName: "DOB",
        type: "date",
        placeholder: "Date Of Birth",
        value: "",
        label: "Date Of Birth",
        width: "130px",
        addFormOrder: 11,
        editFormOrder: 12,
        header: "Date Of Birth",
        derivedValue:
          "DOB=DOB=DOB=DOB=DOB=DOB=DOB=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "DOB",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "dateOfBirth",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "DateOfJoin",
        fieldName: "DateOfJoin",
        type: "date",
        placeholder: "Date Of Join",
        value: "",
        label: "Date Of Join",
        width: "130px",
        addFormOrder: 12,
        editFormOrder: 13,
        header: "Date Of Join",
        derivedValue:
          "DateOfJoin=DateOfJoin=DateOfJoin=DateOfJoin=DateOfJoin=joinDate=DateOfJoin=DateOfJoin=DateOfJoin=DateOfJoin=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "DateOfJoin",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "joinDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "CompanyEmail",
        fieldName: "CompanyEmail",
        type: "email",
        placeholder: "Company Email",
        value: "",
        label: "Company Email",
        width: "150px",
        addFormOrder: 13,
        editFormOrder: 15,
        header: "Company Email",
        derivedValue:
          "CompanyEmail=CompanyEmail=CompanyEmail=CompanyEmail=CompanyEmail=CompanyEmail=CompanyEmail=CompanyEmail=CompanyEmail=CompanyEmail=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "CompanyEmail",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "CompanyEmail",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "AadharNo",
        fieldName: "AadharNo",
        type: "text",
        placeholder: "Aadhar No",
        value: "",
        label: "Aadhar No",
        width: "130px",
        addFormOrder: 9,
        editFormOrder: 10,
        header: "Aadhar No",
        derivedValue:
          "AadharNo=AadharNo=AadharNo=AadharNo=AadharNo=AadharNo=AadharNo=AadharNo=AadharNo=AadharNo=AadharNo=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "AadharNo",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        validationRequired: true,
        regexPattern: "^[0-9]{0,12}$",
        regexData: {
          showMinField: true,
          minLengthRequired: false,
          minLengthText: null,
          showMaxField: true,
          maxLengthRequired: true,
          maxLengthText: "12",
          alphabetsRequired: false,
          showAlphaField: false,
          numbersRequired: true,
          showNumField: true,
          specialCharRequired: false,
          showSCharField: false,
          specialCharText: null,
        },
        textAlign: "center",
        show: true,
        field: "aadharcardNumber",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "EmployeeId",
        fieldName: "EmployeeId",
        type: "uniqueField",
        placeholder: "Employee Id",
        value: "",
        label: "Employee Id",
        width: "120px",
        addFormOrder: 15,
        editFormOrder: 18,
        header: "Employee Id",
        derivedValue:
          "EmployeeId=EmployeeId=EmployeeId=EmployeeId=EmployeeId=EmployeeId=EmployeeId=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "EmployeeId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "employeeId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "BiometricId",
        fieldName: "biometricId",
        type: "uniqueField",
        placeholder: "Biometric Id",
        value: "",
        label: "Biometric Id",
        width: "120px",
        addFormOrder: 16,
        editFormOrder: 19,
        header: "Biometric Id",
        derivedValue:
          "BiometricId=BiometricId=BiometricId=BiometricId=BiometricId=BiometricId=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "biometricId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "biometricId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "PhoneNumber",
        fieldName: "PhoneNumber",
        type: "text",
        placeholder: "Phone Number",
        value: "",
        label: "Phone Number",
        width: "130px",
        addFormOrder: 17,
        editFormOrder: 20,
        header: "Phone Number",
        derivedValue:
          "PhoneNumber=PhoneNumber=PhoneNumber=PhoneNumber=PhoneNumber=PhoneNumber=PhoneNumber=PhoneNumber=PhoneNumber=PhoneNumber=PhoneNumber=PhoneNumber=PhoneNumber=PhoneNumber=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "PhoneNumber",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        validationRequired: true,
        regexPattern: "^[0-9]{0,10}$",
        regexData: {
          showMinField: true,
          minLengthRequired: false,
          minLengthText: null,
          showMaxField: true,
          maxLengthRequired: true,
          maxLengthText: "10",
          alphabetsRequired: false,
          showAlphaField: false,
          numbersRequired: true,
          showNumField: true,
          specialCharRequired: false,
          showSCharField: false,
          specialCharText: null,
        },
        textAlign: "center",
        show: true,
        field: "phoneNumber",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "emergencyContact",
        fieldName: "emergencyContact",
        type: "text",
        placeholder: "Emergency Contact",
        value: "",
        label: "Emergency Contact",
        width: "130px",
        addFormOrder: 18,
        editFormOrder: 21,
        header: "Emergency Contact",
        derivedValue:
          "emergencyContact=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "emergencyContact",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        validationRequired: true,
        regexPattern: "^[0-9]{0,10}$",
        regexData: {
          showMinField: true,
          minLengthRequired: false,
          minLengthText: null,
          showMaxField: true,
          maxLengthRequired: true,
          maxLengthText: "10",
          alphabetsRequired: false,
          showAlphaField: false,
          numbersRequired: true,
          showNumField: true,
          specialCharRequired: false,
          showSCharField: false,
          specialCharText: null,
        },
        textAlign: "center",
        show: true,
        field: "emergencyContact",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "skypeId",
        fieldName: "skypeId",
        type: "uniqueField",
        placeholder: "Skype ID",
        value: "",
        label: "Skype ID",
        width: "120px",
        addFormOrder: 19,
        editFormOrder: 22,
        header: "Skype ID",
        derivedValue:
          "skypeId=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "skypeId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "skypeId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "bitbuketId",
        fieldName: "bitbuketId",
        type: "uniqueField",
        placeholder: "Bitbuket ID",
        value: "",
        label: "Bitbuket ID",
        width: "120px",
        addFormOrder: 20,
        editFormOrder: 23,
        header: "Bitbuket ID",
        derivedValue:
          "bitbuketId=bitbuketId=bitbuketId=bitbuketId=bitbuketId=BitBucketId=BitBucketId=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "bitbuketId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "bitbuketId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "qualification",
        fieldName: "qualification",
        type: "text",
        placeholder: "Qualification",
        value: "",
        label: "Qualification",
        width: "130px",
        addFormOrder: 21,
        editFormOrder: 24,
        header: "Qualification",
        derivedValue:
          "qualification=qualification=qualification=qualification=Qualification=Qualification=Qualification=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Qualification",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "qualification",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "document",
        fieldName: "document",
        type: "dropDown",
        placeholder: "Document",
        value: "",
        label: "Document",
        width: "130px",
        addFormOrder: 25,
        editFormOrder: 28,
        header: "document",
        derivedValue: "document=document=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "document",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        globalSearchField: "true",
        controllerId: null,
        options: [
          { label: '10th Certificate', value: '10th Certificate', color: "info" },
          { label: 'Inter', value: 'Inter', color: 'primary' },
          { label: 'Diploma', value: 'Diploma', color: "secondary" },
          { label: 'Degree', value: 'Degree', color: "warning" },
          { label: 'BE/B-Tech', value: 'BE/B-Tech', color: "success" },
          { label: 'MBA', value: 'MBA', color: 'success' },
          { label: 'ME/M-Tech', value: 'ME/M-Tech', color: "danger" },
          { label: 'MCA', value: 'MCA', color: 'dark' },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: '10th Certificate', value: '10th Certificate', color: "info" },
          { label: 'Inter', value: 'Inter', color: 'primary' },
          { label: 'Diploma', value: 'Diploma', color: "secondary" },
          { label: 'Degree', value: 'Degree', color: "warning" },
          { label: 'BE/B-Tech', value: 'BE/B-Tech', color: "success" },
          { label: 'MBA', value: 'MBA', color: 'success' },
          { label: 'ME/M-Tech', value: 'ME/M-Tech', color: "danger" },
          { label: 'MCA', value: 'MCA', color: 'dark' },
        ],
        // dependent: [],
        textAlign: "center",
        show: true,
        field: "document",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        isMultiSelect: true
      },
      {
        name: "Consultancy",
        fieldName: "Consultancy",
        type: "text",
        placeholder: "Consultancy",
        value: "",
        label: "Consultancy",
        width: "130px",
        addFormOrder: 22,
        editFormOrder: 25,
        header: "Consultancy",
        derivedValue:
          "consultancy=consultancy=consultancy=consultancy=Consultancy=Consultancy=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Consultancy",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "consultancy",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "PreviousExperience",
        fieldName: "PreviousExperience",
        type: "text",
        placeholder: "Previous Experience",
        value: "",
        label: "Previous Experience",
        width: "130px",
        addFormOrder: 23,
        editFormOrder: 26,
        header: "Previous Experience",
        derivedValue:
          "previousExperience=previousExperience=previousExperience=PreviousExperience=PreviousExperience=PreviousExperience=PreviousExperience=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "PreviousExperience",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "previousExperience",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "previousCompany",
        fieldName: "previousCompany",
        type: "text",
        placeholder: "Previous Company",
        value: "",
        label: "Previous Company",
        width: "130px",
        addFormOrder: 24,
        editFormOrder: 27,
        header: "Previous Company",
        derivedValue:
          "previousCompany=previousCompany=previousCompaney=previousCompaney=PreviousCompany=PreviousCompany=PreviousCompany=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "previousCompany",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "previousCompany",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "EmploymentType",
        fieldName: "employmentType",
        type: "dropDown",
        placeholder: "Employment Type",
        value: "",
        label: "Employment Type",
        width: "130px",
        addFormOrder: 25,
        editFormOrder: 28,
        header: "Employment Type",
        derivedValue:
          "employmentType=employmentType=employmentType=EmploymentType=EmploymentType=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "EmploymentType",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          { label: "Permanent", value: "Permanent", color: "success" },
          { label: "Contract", value: "Contract", color: "primary" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "Permanent", value: "Permanent", color: "primary" },
          { label: "Contract", value: "Contract", color: "primary" },
        ],
        // dependent: [],
        textAlign: "center",
        show: true,
        field: "employmentType",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Gender",
        fieldName: "Gender",
        type: "radio",
        placeholder: "Gender",
        value: "Female",
        label: "Gender",
        width: "120px",
        addFormOrder: 26,
        editFormOrder: 31,
        header: "Gender",
        derivedValue: "gender=gender=gender=Gender=Gender=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Gender",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "Female", value: "Female" },
          { label: "Male", value: "Male" },
        ],
        fieldType: "radio",
        filterElement: [
          { label: "Female", value: "Female" },
          { label: "Male", value: "Male" },
        ],
        textAlign: "center",
        show: true,
        field: "gender",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "TemporaryAddress",
        fieldName: "TemporaryAddress",
        type: "textarea",
        placeholder: "Temporary Address",
        value: "",
        label: "Temporary Address",
        width: "200px",
        addFormOrder: 27,
        editFormOrder: 32,
        header: "Temporary Address",
        derivedValue:
          "temporaryAddress=temporaryAddress=temporaryAddress=temporaryAddress=TemporaryAddress=TemporaryAddress=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "TemporaryAddress",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "temporaryAddress",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "PermanentAddress",
        fieldName: "PermanentAddress",
        type: "textarea",
        placeholder: "Permanent Address",
        value: "",
        label: "Permanent Address",
        width: "200px",
        addFormOrder: 28,
        editFormOrder: 33,
        header: "Permanent Address",
        derivedValue:
          "permanentAddress=permanentAddress=permanentAddress=permanentAddress=PermanentAddress=PermanentAddress=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "PermanentAddress",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        style: { width: '250px', textOverflow: "ellipsis" },
        show: true,
        field: "permanentAddress",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "BankAccountNo",
        fieldName: "BankAccountNo",
        type: "text",
        placeholder: "Bank Account No",
        value: "",
        label: "Bank Account No",
        width: "130px",
        addFormOrder: 29,
        editFormOrder: 34,
        header: "Bank Account No",
        derivedValue:
          "accountNo=accountNo=accountNo=BankAccountNo=BankAccountNo=BankAccountNo=BankAccountNo=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "BankAccountNo",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        validationRequired: true,
        regexPattern: "^.{0,10000}$",
        regexData: {
          showMinField: true,
          minLengthRequired: false,
          minLengthText: null,
          showMaxField: true,
          maxLengthRequired: false,
          maxLengthText: null,
          alphabetsRequired: false,
          showAlphaField: false,
          numbersRequired: false,
          showNumField: false,
          specialCharRequired: false,
          showSCharField: false,
          specialCharText: null,
        },
        textAlign: "center",
        show: true,
        field: "accountNo",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "UANNumber",
        fieldName: "UANNumber",
        type: "uniqueField",
        placeholder: "UAN Number",
        value: "",
        label: "UAN Number",
        width: "120px",
        addFormOrder: 30,
        editFormOrder: 35,
        header: "UAN Number",
        derivedValue: "uanNumber=uanNumber=UANNumber=UANNumber=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "UANNumber",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "uanNumber",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "IFSCCode",
        fieldName: "IFSCCode",
        type: "uniqueField",
        placeholder: "IFSC Code",
        value: "",
        label: "IFSC Code",
        width: "120px",
        addFormOrder: 31,
        editFormOrder: 36,
        header: "IFSC Code",
        derivedValue:
          "ifscCode=ifscCode=ifscCode=ifscCode=IFSCCode=IFSCCode=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "IFSCCode",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "ifscCode",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "EmployeeDocumentDriveLink",
        fieldName: "EmployeeDocumentDriveLink",
        type: "textarea",
        placeholder: "Employee Document Drive Link",
        value: "",
        label: "Employee Document Drive Link",
        width: "200px",
        addFormOrder: 33,
        editFormOrder: 37,
        header: "Employee Document Drive Link",
        derivedValue:
          "employeeDocumentDriveLink=employeeDocumentDriveLink=employeeDocumentDriveLink=employeeDocumentDriveLink=employeeDocumentDriveLink=employeeDocumentDriveLink=employeeDocumentDriveLinks=EmployeeDocumentDriveLink=EmployeeDocumentDriveLink=EmployeeDocumentDriveLink=EmployeeDocumentDriveLink=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "EmployeeDocumentDriveLink",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: true,
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        fieldType: "driveLink",
        field: "employeeDocumentDriveLinks",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "IdCardReceived",
        fieldName: "IdCardReceived",
        type: "radio",
        placeholder: "Id Card Received ?",
        value: "",
        label: "Id Card Received ?",
        width: "120px",
        addFormOrder: 35,
        editFormOrder: 38,
        header: "Id Card Received ?",
        derivedValue:
          "idCardReceived=idCardReceived=idCardReceived=idCardReceived=IdCardRecieved=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "IdCardReceived",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" },
        ],
        fieldType: "radio",
        filterElement: [
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" },
        ],
        textAlign: "center",
        show: true,
        field: "idCardReceived",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Orientation_date",
        fieldName: "Orientation_date",
        type: "date",
        placeholder: "Orientation Date",
        value: "",
        label: "Orientation Date",
        width: "130px",
        addFormOrder: 32,
        editFormOrder: 36,
        header: "Orientation Date",
        derivedValue:
          "orientationDate=orientationDate=orientationDate=Orientation_date=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Orientation_date",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "orientationDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Termination_date",
        fieldName: "Termination_date",
        type: "date",
        placeholder: "Termination Date",
        value: "",
        label: "Termination Date",
        width: "130px",
        addFormOrder: 34,
        editFormOrder: 37,
        header: "Termination Date",
        derivedValue:
          "terminationDate=terminationDate=terminationDate=Termination_date=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Termination_date",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "terminationDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Bank_name",
        fieldName: "Bank_name",
        type: "text",
        placeholder: "Bank Name",
        value: "",
        label: "Bank Name",
        width: "130px",
        addFormOrder: 37,
        editFormOrder: 41,
        header: "Bank Name",
        derivedValue: "bankName=bankName=bankName=bankName=Bank_name=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Bank_name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "bankName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "BranchName",
        fieldName: "BranchName",
        type: "text",
        placeholder: "Branch Name",
        value: "",
        label: "Branch Name",
        width: "130px",
        addFormOrder: 38,
        editFormOrder: 42,
        header: "Branch Name",
        derivedValue: "branch=branch=branch=BranchName=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "BranchName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "branch",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "image",
        fieldName: "image",
        type: "fileUpload",
        placeholder: "Attach File",
        value: "",
        label: "Attach File",
        width: "120px",
        addFormOrder: 39,
        editFormOrder: 43,
        header: "Attach File",
        derivedValue:
          "attatchFile=attatchFile=image=image=image=file=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "image",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        fieldType: "fileUpload",
        fileType: "img/video",
        maxFileSize: 5,
        isMultipleRequired: true,
        imagePath: "employee",
        textAlign: "center",
        show: true,
        field: "image",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "totalLeaves",
        fieldName: "totalLeaves",
        type: "text",
        placeholder: "Total Leaves",
        value: "",
        label: "Total Leaves",
        width: "120px",
        addFormOrder: 48,
        editFormOrder: 48,
        header: "Total Leaves",
        derivedValue:
          "totalLeaves=totalLeaves=totalLeaves=totalLeaves=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "totalLeaves",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "totalLeaves",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Status",
        type: "dropDown",
        placeholder: "Status",
        value: "",
        label: "Status",
        width: "130px",
        addFormOrder: 40,
        editFormOrder: 44,
        header: "Status",
        derivedValue: "status=status=status=Status=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Status",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: true,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          { label: "Active", value: "Active", color: "success" },
          { label: "Pending", value: "Pending", color: "danger" },
          { label: "InActive", value: "InActive", color: "warning" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "Active", value: "Active", color: "success" },
          { label: "Pending", value: "Pending", color: "danger" },
          { label: "InActive", value: "InActive", color: "warning" },
        ],
        // dependent: [],
        textAlign: "center",
        show: true,
        field: "status",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "pancardNumber",
        fieldName: "pancardNumber",
        type: "text",
        placeholder: "PAN",
        value: "",
        label: "PAN",
        width: "130px",
        addFormOrder: 43,
        editFormOrder: 43,
        header: "PAN",
        derivedValue:
          "pancardNumber=pancardNumber=pancardNumber=pancardNumber=pan=pan=pan=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "pancardNumber",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        validationRequired: true,
        regexPattern: "^[a-zA-Z0-9]{0,10}$",
        regexData: {
          showMinField: false,
          minLengthRequired: false,
          minLengthText: null,
          showMaxField: true,
          maxLengthRequired: true,
          maxLengthText: "10",
          alphabetsRequired: true,
          showAlphaField: true,
          numbersRequired: true,
          showNumField: true,
          specialCharRequired: false,
          showSCharField: false,
          specialCharText: null,
        },
        textAlign: "center",
        show: true,
        field: "pancardNumber",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "true",
        displayineditForm: "true",
        globalSearchField: "false",
        isFieldRequired: "false",
        mobile: true,
        tDisplay: true,
      },
    ];
    return data;
  };
  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };
  // submit1 = item => {
  //   let x = employeesFields
  //   let objemployees = {}

  //   objemployees["_id"] = item[0]["_id"]
  //   for (let x2 of x) {
  //     objemployees[x2] = item[0][x2]
  //   }
  //   let formFields = this.getFormFields()

  //   formFields = formFields.filter(y => x.includes(y.name))

  //   this.setState({
  //     openNewUserModal: true,
  //     item: objemployees,
  //     newFormFields: formFields
  //   });
  // }

  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    // window.location.reload();
    if (dataTableRef.current) {
      const { getDataFromServer, filterCriteria } = await dataTableRef.current;
      getDataFromServer(filterCriteria);
    }
  };
  const getChangedRoleOptions = () => {
    let fields = getFormFields();
    if (fields && fields.length > 0) {
      for (let obj of fields) {
        if (obj.name == "role") {
          obj.options = rolesList && rolesList.length > 0 ? rolesList : [];
        }
      }
    }
    return fields;
  };

  const saveDataToServer = async (item, field, value, getDataFromServer) => {
    let userBody;
    if (item?.length > 0 && field && value) {
      let selectedIds = item.map((row) => row._id);
      userBody = {
        selectedIds: selectedIds,
        updatedDetails: { [field]: value },
      };
    } else {
      return;
    }
    if (userBody) {
      let method, apiUrl;
      method = "POST";
      apiUrl = `${apiCalls.employees}/multiUpdate`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          if (response && response.respCode) {
            getDataFromServer();
            showToasterMessage(response.respMessage, "success");
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };

  //get monthly data of all employees based on leaves and keeplng the commented code for reference.
  // const getMonthlyReport = async () => {
  //   await fetchMethodRequest('GET', 'employees/employeeAttendance')
  //     .then(async (response) => {
  //       if (response) {
  //         const workbook = new ExcelJS.Workbook();

  //         const headers = [
  //           { label: 'Employee ID', key: 'employeeId' },
  //           { label: 'Employee Name', key: 'employeeName' },
  //           { label: 'Employment Type', key: 'employmentType' },
  //           { label: 'JoinDate', key: 'joinDate' },
  //           { label: 'LeaveBalance', key: 'leaveBalance' },
  //           { label: 'LeaveDates', key: 'leaveDates' },
  //           { label: 'TotalLeaves', key: 'totalLeaves' },
  //           { label: 'Total WorkingDays', key: 'totalworkingDays' },
  //           { label: 'Employee WorkingDays', key: 'employeeWorkingDays' },
  //           { label: 'Timesheet Filled Days', key:'attendanceCount'},
  //           { label: 'Work From Office', key:'totalWorkFromOfficeDays'},
  //           { label: 'Work From Home', key: 'totalWorkFromHomeDays' }
  //         ];

  //         // Function to format a row based on headers
  //         const formatRow = (employee) => headers.map(header => {
  //           let value = employee[header.key];
  //           if (header.key === 'joinDate' || header.key === 'leaveDates') {
  //             if (header.key === 'joinDate') {
  //               value = moment(value).format('DD-MM-YYYY');
  //             } else if (header.key === 'leaveDates' && Array.isArray(value)) {
  //               value = value.map(date => date).join('\n');
  //             }
  //           }
  //           return value;
  //         });

  //         // Add Current Month sheet
  //         const sheet1 = workbook.addWorksheet('Current Month');
  //         sheet1.addRow(headers.map(header => header.label));
  //         response.currentMonth.forEach(employee => sheet1.addRow(formatRow(employee)));

  //         // Add Previous Month sheet
  //         const sheet2 = workbook.addWorksheet('Previous Month');
  //         sheet2.addRow(headers.map(header => header.label));
  //         response.previousMonth.forEach(employee => sheet2.addRow(formatRow(employee)));

  //         // Save the workbook to a blob
  //         const buffer = await workbook.xlsx.writeBuffer();
  //         const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

  //         // Using FileSaver to trigger the download
  //         saveAs(blob, 'Report.xlsx');
  //       }
  //     }).catch((error) => {
  //       return error;
  //     })
  // }

  const getMonthlyReport = async () => {
    await fetchMethodRequest('GET', 'employees/employeeAttendance')
      .then(async (response) => {
        if (response) {
          const workbook = new ExcelJS.Workbook();
  
          const headers = [
            { label: 'Employee ID', key: 'employeeId' },
            { label: 'Employee Name', key: 'employeeName' },
            { label: 'Employment Type', key: 'employmentType' },
            { label: 'Join Date', key: 'joinDate' },
            { label: 'Leave Balance', key: 'leaveBalance' },
            { label: 'Leave Dates', key: 'leaveDates' },
            { label: 'Total Leaves Taken', key: 'totalLeaves' },
            { label: 'Missed Apply Leave Days', key: 'missedApplyLeaveDays' },
            { label: 'Missed PTO Days', key: 'totalMissedPTOdays' },
            { label: 'Total Working Days', key: 'totalworkingDays' },
            { label: 'Employee Working Days', key: 'employeeWorkingDays' },
            { label: 'Timesheet Filled Days', key: 'attendanceCount' },
            { label: 'No of Working Days Till Date', key: 'workingDaysTillToday' },
            { label: 'Missed Timesheet filled Dates', key: 'totalMissedfilledDates' },
            { label: 'Work From Office Days', key: 'totalWorkFromOfficeDays' },
            { label: 'Work From Home Days', key: 'totalWorkFromHomeDays' },
            { label: 'Biometric Attendance Days', key: 'NoofBioMetricDays' },
            { label: 'Client Location Working Days', key: 'ClientLocationWorkingdates' },
            { label: 'Total Client Location Days', key: 'totalWorkFromClinetLocationDays' },
            { label: 'Timesheet Filled %', key: 'timeSheetFilledPercentage' },
            { label: 'Weekend or Public Holiday Worked Days', key: 'WeekendOrPublicHolidayWorkedDays' },
            { label: 'No of Days Worked on Week offs or Public Holidays', key: 'NoofDaysWorkedOnWeekOffsOrPublicHolidays' }
          ];
  
          // Function to format a row based on headers
          const formatRow = (employee) => headers.map(header => {
            let value = employee[header.key];
  
            // Format arrays into strings
            if (Array.isArray(value) && header.key !== 'leaveDates') {
              value = value.map(date => moment(date).format('DD-MM-YYYY')).join(', ');
            }

            if (Array.isArray(value) && header.key === 'leaveDates') {
              value = value.map(date => {
                const match = date.match(/(\d{4}-\d{2}-\d{2})\(([^)]+)\)/);
                if (match) {
                  const formattedDate = moment(match[1]).format('DD-MM-YYYY');
                  return `${formattedDate}(${match[2]})`;
                }
                return date;
              }).join(', ');
            }
  
            // Format dates
            if (header.key === 'joinDate' && value) {
              value = moment(value).format('DD-MM-YYYY');
            }
  
            return value;
          });

          // color for reuse
          const colorLow = { argb: 'FFEEA8A8' };     // Red for < 25%
          const colorMedium = { argb: 'FFFFFFA8' };  // Yellow for 25-50%
          const colorHigh = { argb: 'FFA8FFA8' };    // Green for 50-75%
  
          // for the header width adjustments
          const addSheetWithFormatting = (sheet, data) => {
            headers.forEach((header, index) => {
              // Set column width based on header text length (minimum 10, maximum 30)
              const width = Math.min(Math.max(header.label.length * 1.5, 10), 30);
              sheet.getColumn(index + 1).width = width;
            });
  
            // Style the header row
            const headerRow = sheet.addRow(headers.map(header => header.label));
            headerRow.eachCell((cell) => {
              cell.font = { bold: true };
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFD3D3D3' } // Light gray for header
              };
              cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            });
  
            // Add data rows
            data.forEach(employee => {
              const row = sheet.addRow(formatRow(employee));
  
              // Highlight row based on timesheet filled percentage
              const percentage = parseFloat(employee.timeSheetFilledPercentage);
  
              if (percentage < 25) {
                row.eachCell(cell => {
                  cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: colorLow
                  };
                });
              } else if (percentage < 50) {
                row.eachCell(cell => {
                  cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: colorMedium
                  };
                });
              } else if (percentage < 75) {
                row.eachCell(cell => {
                  cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: colorHigh
                  };
                });
              }
            });
  
            // Add empty row as separator
            sheet.addRow([]);
  
            // Add legend rows at the bottom
            const legendTitle = sheet.addRow(['Color Legend']);
            legendTitle.getCell(1).font = { bold: true, size: 12 };
            sheet.mergeCells(`A${legendTitle.number}:D${legendTitle.number}`);
  
            const legend1 = sheet.addRow(['Red', 'Timesheet Filled < 25%']);
            legend1.getCell(1).fill = { type: 'pattern', pattern: 'solid', fgColor: colorLow };
  
            const legend2 = sheet.addRow(['Yellow', 'Timesheet Filled 25% - 49%']);
            legend2.getCell(1).fill = { type: 'pattern', pattern: 'solid', fgColor: colorMedium };
  
            const legend3 = sheet.addRow(['Green', 'Timesheet Filled 50% - 74%']);
            legend3.getCell(1).fill = { type: 'pattern', pattern: 'solid', fgColor: colorHigh };
  
            const legend4 = sheet.addRow(['No Color', 'Timesheet Filled ≥ 75%']);
          };
  
          // Add sheets with conditional formatting
          const sheet1 = workbook.addWorksheet('Current Month');
          addSheetWithFormatting(sheet1, response.currentMonth);
  
          const sheet2 = workbook.addWorksheet('Previous Month');
          addSheetWithFormatting(sheet2, response.previousMonth);
  
          // Save the workbook to a blob
          const buffer = await workbook.xlsx.writeBuffer();
          const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  
          // Using FileSaver to trigger the download
          saveAs(blob, 'Report.xlsx');
        }
      }).catch((error) => {
        return error;
      });
  };
  

  const openLeaveUpdateModal = (item) => {
    setLeaveData(item)
    setIsOpenLeaveBalanceModal(true)
  }

  const closeLeaveUpdateModal = () => {
    setIsOpenLeaveBalanceModal(false)
  }

  //Displaying custom action icon for leave balance 
  const customLeavesButton = (item, getData, filters) => {

    return (
      <>
        {loginRole.roleType == 'Admin' ?
          <FontAwesomeIcon
            className='genderIconAlignment'
            color='white'
            icon='check-circle'
            data-toggle="tool-tip"
            title="Leaves"
            style={{ color: '#024a88', width: '15', marginRight: '10px' }}
            onClick={() => openLeaveUpdateModal(item)}
          />
          : ''}
      </>
    )
  }

  const handleToggle = (item, getData, filters) => {
    item.isReportingTo ? openingEnableDisableTimesheetModal(item, getData, filters) : null 
  };

  const customEnableTimesheetButton = (item, getData, filters) => {
    return (
      <div className="toggler">
        <input id={`toggler-${item._id}`} name="toggler-1" type="checkbox" checked={item.enablePreviousTimesheet} onChange={() => handleToggle(item, getData, filters)} />
        <label htmlFor={`toggler-${item._id}`}>
          {item.enablePreviousTimesheet ? (
            <svg className="toggler-on" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <polyline className="path check" points="100.2,40.2 51.5,88.8 29.8,67.5"></polyline>
            </svg>
          ) : (
            <svg className="toggler-off" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <line className="path line" x1="34.4" y1="34.4" x2="95.8" y2="95.8"></line>
              <line className="path line" x1="95.8" y1="34.4" x2="34.4" y2="95.8"></line>
            </svg>
          )}
        </label>
      </div>
    )
  }

  const openingEnableDisableTimesheetModal = (item, getData, filters) => {
    setIsOpenEnableDisableTimesheetModal(true)
    setSaveRowData(item)
    setEnableDisableTimesheet(item?.enablePreviousTimesheet)
  }

  const closingEnableDisableTimesheetModal =  async() => {
    const { getDataFromServer, filterCriteria } = await dataTableRef.current;
    setIsOpenEnableDisableTimesheetModal(false)
    getDataFromServer(filterCriteria);
  }

  const onConfirmation = async () => {
    const { getDataFromServer, filterCriteria } = await dataTableRef.current;

    let userBody;
    if (enableDisableTimesheet) {
      userBody = {
        enablePreviousTimesheet: false
      }
    } else {
      userBody = {
        enablePreviousTimesheet: true
      }
    }

    if (userBody) {
      let method, apiUrl;
      method = "PUT";
      apiUrl = `${apiCalls.employees}/updateEnableTimeSheets/${saveRowData._id}`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          if (response && response.respCode) {
            getDataFromServer(filterCriteria);
            showToasterMessage(response.respMessage, "success");
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
          setIsOpenEnableDisableTimesheetModal(false)
        })
        .catch((err) => {
          return err;
        });
    }
  }

  const isEditPermission = rolePermission?.type == "Edit" ? true : false
  const actionTypes = [
    {
      name: "Delete",
      options: [
        {
          label: "Delete",
          value: "Delete",
          show: rolePermission?.actions?.includes("Delete") ? true : false,
          multiple: true,
        },
      ],
    },

    {
      name: "Active",
      action: saveDataToServer,
      options: [
        {
          label: "Active",
          field: "status",
          value: "Active",
          show: rolePermission?.actions?.includes("Approve/Reject") ? true : false,
          multiple: true,
        },
      ],
    },
    {
      name: "Pending",
      action: saveDataToServer,
      options: [
        {
          label: "Pending",
          field: "status",
          value: "Pending",
          show: rolePermission?.actions?.includes("Approve/Reject") ? true : false,
          multiple: true,
        },
      ],
    },
    {
      name: "InActive",
      action: saveDataToServer,
      options: [
        {
          label: "InActive",
          field: "status",
          value: "InActive",
          show: rolePermission?.actions?.includes("Approve/Reject") ? true : false,
          multiple: true,
        },
      ],
    },
  ];

  const getDataFromServer = async (type) => {
    if (dataTableRef.current) {
      const { getDataFromServer, filterCriteria } = await dataTableRef.current;
      getDataFromServer(filterCriteria);
    }
  }

  return (
    <span>
      {gotRoles && (
        <DataTables
          onRef={(ref) => (dataTableRef.current = ref)}
          {...props}
          getTableFields={getTableFields}
          formFields={getChangedRoleOptions}
          // MobileTableFields={this.getMobileTableFields}
          // getTableFields={this.getTableFields}
          // formFields={this.getFormFields}
          //
          addRequired={isEditPermission}
          editRequired={isEditPermission}
          deleteRequired={isEditPermission}
          viewRequired={isEditPermission}
          exportRequired={isEditPermission}
          sample={true}
          // globalSearch={'Display Name/Email'}
          // type='Employees'
          // apiUrl={apiCalls.Employees}

          // exportRequried={true}

          printRequried={true}
          actionsTypes={actionTypes}
          // addRequried={insertAdd}
          // editRequired={true}
          // deleteRequired={true}
          // viewRequired={true}
          settingsRequired={true}
          filterRequired={false}
          gridRequried={true}
          exportToCsv={true}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          sampleFilePath={filePath.employees}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          globalSearch={
            "name/email/address/role/FirstName/LastName/CompanyEmail/Consultancy/PreviousExperience/PreviousCompany/EmploymentType/TemporaryAddress/PermanentAddress/EmployeeDocumentDriveLink/Bank_name/BranchName/Status/pan"
          }
          displayName="Employees"
          type="Employees"
          routeTo={apiCalls.employees}
          sideFormLeftOrRight="right"
          displayViewOfForm="screen"
          apiResponseKey={apiCalls.employees?.toLowerCase()}
          apiUrl={apiCalls.employees}
          selectedId={params.id}
          displayActionsInTable={"actions"}
          customLeavesButton={customLeavesButton}
          customEnableTimesheetButton={customEnableTimesheetButton}
          defaultCriteria={[
            { key: "status", value: ["Active", "Pending"], type: "in" },
          ]}
          getMonthlyReport={getMonthlyReport}
        />
      )}
      {isOpenFormModal ? (
        <FormModal
          // onRef={(ref) => (this.formModalRef = ref)}
          formType="edit"
          openFormModal={isOpenFormModal}
          formFields={formFields}
        />
      ) : null}
      {openNewUserModal ? (
        <NewUserModal
          openNewUserModal={openNewUserModal}
          cancelReset={cancelReset}
          item={item}
          newFormFields={newFormFields}
          recordId={item._id}
          entityType="employees"
          apiUrl={apiCalls.employees}
        />
      ) : null}
      {isOpenLeaveBalanceModal ? (
        <LeaveBalanceModal
          leaveData={leaveData}
          type={'timeSheets'}
          isOpenLeaveBalanceModal={isOpenLeaveBalanceModal}
          closeLeaveUpdateModal={closeLeaveUpdateModal}
          getDataFromServer={getDataFromServer}
        />
      ) : null}
      {isOpenEnableDisableTimesheetModal ? (
        <ConfirmationModal
          openConfirmationModal={isOpenEnableDisableTimesheetModal}
          closeConfirmationModal={closingEnableDisableTimesheetModal}
          confirm={onConfirmation}
          text={ !enableDisableTimesheet ? 
            " allow this employee to fill timesheets for previous dates"
            : " prevent this user from filling timesheets for previous dates"
          }
        />
      ) : null}
    </span>
  );
};

export default Employees;
