import React, { useEffect, useState, useRef } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
import ApproveOrReject from "../../CommonModals/ApproveOrReject";
// config file

const Tasks = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);

  const [meetingNotes, setMeetingNotes] = useState([]);

  let dataTableRef = useRef(null);
  const params = useParams();
  useEffect(() => {
    let screenPermissions = RolePermissions.screenPermissions("Tasks");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
  }, []);

  useEffect(() => {
    const getQueryParams = () => {
      const params = new URLSearchParams(window.location.search);
      return JSON.parse(decodeURIComponent(params.get('notes')));
    };

    const notes = getQueryParams();
    if (notes) {
      let method, apiUrl, filterCriteria;
      method = "GET";
      filterCriteria = { limit: 20, page: 1, criteria: [{ "key": "_id", "value": notes?.projectId, "type": "eq" }, { "key": "status", "value": ["Active", "Pending"], "type": "in" }], sortfield: 'created', direction: 'desc' };
      apiUrl = `projects?filter=${JSON.stringify(filterCriteria)}`;
      return fetchMethodRequest(method, apiUrl)
        .then(async (response) => {
          const projectObject = response.projects[0];
          const meetingObject = response.projects[0].meetingNotes.find(note => note._id === notes?.projectNotesId);
          setMeetingNotes({
            meetingDate: meetingObject,
            projectNotes: meetingObject.projectNotes,
            projectData: projectObject
          });
        })
        .catch((err) => {
          return err;
        });
    } else {
      setMeetingNotes({});
      return;
    }
  }, [window.location.search]);

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };

  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };

  const getTableFields = () => {
    let roleTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Admin",
        value: "Admin",
      },
      {
        label: "Super Admin",
        value: "Super Admin",
      },
      {
        label: "User",
        value: "User",
      },
    ];
    let StatusTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "S.No",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        fieldName: "createdByName",
        type: "Text",
        placeholder: "Created By",
        value: "",
        label: "Created By",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Created By",
        derivedValue: "createdByName=createdByName=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "createdByName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: 1001,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        fieldType: "Text",
        textAlign: "center",
        show: true,
        field: "createdByName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        fieldName: "created",
        type: "date",
        placeholder: "Created",
        value: "",
        label: "Created",
        width: 90,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Created",
        derivedValue: "created=created=created=created=undefined",
        capitalizeTableText: "",
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: true,
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "created",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        fieldName: "updated",
        type: "date",
        placeholder: "Updated",
        value: "",
        label: "Updated",
        width: 90,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Updated",
        derivedValue: "updated=updated=updated=undefined",
        capitalizeTableText: "",
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "updated",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "projectName",
        fieldName: "projectName",
        type: "relateAutoComplete",
        placeholder: "Project name",
        value: "",
        label: "Project",
        width: "150px",
        addFormOrder: 1,
        editFormOrder: 1,
        header: "Project",
        derivedValue:
          "projectName=projectName=projectName=projectName=projectName=projects=Projects=Projects=Projects=Projects=Projects=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "projectName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1007,
        isClickable: false,
        isAddToIndex: "",
        isBulkUploadField: false,
        hasDependency: "false",
        searchField: "name",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["ProjectName"],
        controllerName: "Project",
        searchApi: "projects",
        isMultiple: false,
        textAlign: "center",
        show: true,
        field: "projectId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "taskSummary",
        fieldName: "taskSummary",
        type: "text",
        placeholder: "Task Summary",
        value: "",
        label: "Task Summary",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "Task Summary",
        derivedValue:
          "taskSummary=taskSummary=taskSummary=taskSummary=TaskSummary=TaskSummary=TaskSummary=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "taskSummary",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: true,
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "left",
        show: true,
        field: "name",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#f17038",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "priority",
        fieldName: "priority",
        type: "dropDown",
        placeholder: "Priority",
        value: "",
        label: "Priority",
        width: "130px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "Priority",
        derivedValue:
          "priority=priority=priority=priority=priority=priority=Priority=Priority=Priority=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "priority",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          { label: "Blocker", value: "blocker", color: "warning" },
          { label: "Major", value: "major", color: "success" },
          { label: "Minor", value: "minor", color: "primary" },
          { label: "Critical", value: "critical", color: "danger" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "Blocker", value: "blocker", color: "primary" },
          { label: "Major", value: "major", color: "primary" },
          { label: "Minor", value: "minor", color: "primary" },
          { label: "Critical", value: "critical", color: "primary" },
        ],
        defaultValues: ['critical', 'major', 'minor', 'blocker'],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "priority",
        showOrHideFields: [],
        fieldName: "priority",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },

      {
        name: "priorityNumber",
        type: "number",
        placeholder: "priorityNumber",
        label: "Priority Number",
        width: "120px",
        addFormOrder: 9,
        editFormOrder: 9,
        header: "Priority Number",
        derivedValue: "priorityNumber=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "priorityNumber",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "priorityNumber",
        showOrHideFields: [],
        fieldName: "priorityNumber",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "type",
        fieldName: "type",
        type: "dropDown",
        placeholder: "Type",
        value: "New",
        label: "Type",
        width: "130px",
        addFormOrder: 12,
        editFormOrder: 12,
        header: "Type",
        derivedValue: "type=type=type=type=type=type=Type=Type=Type=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "type",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          { label: "New", value: "new", color: "primary" },
          { label: "Defect", value: "defect", color: "secondary" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "New", value: "new", color: "primary" },
          { label: "Defect", value: "defect", color: "secondary" },
        ],
        defaultValues: ['new', 'defect'],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "type",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "assignedTo",
        fieldName: "assignedTo",
        type: "dropDown",
        placeholder: "Assigned To",
        value: "",
        label: "Assigned To",
        width: "150px",
        addFormOrder: 15,
        editFormOrder: 15,
        header: "Assigned To",
        derivedValue:
          "assignedTo=assignedTo=assignedTo=assignedTo=assignedTo=assignedTo=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "assignedTo",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1001,
        isClickable: false,
        isAddToIndex: "",
        isBulkUploadField: false,
        hasDependency: "false",
        searchField: "displayName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["name"],
        controllerName: "employee",
        searchApi: "employees",
        isMultiple: false,
        textAlign: "center",
        show: true,
        field: "assignedTo",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      // {
      //   name: "status",
      //   type: "dropDown",
      //   placeholder: "Status",
      //   label: "Status",
      //   width: "130px",
      //   addFormOrder: 11,
      //   editFormOrder: 11,
      //   header: "Status",
      //   derivedValue:
      //     "status=Status=Status=Status=Status=Status=Status=Status=Status=Status=Status=Status=undefined",
      //   capitalizeTableText: true,
      //   sortable: true,
      //   filter: true,
      //   actions: [],
      //   actionsNumber: [],
      //   id: "status",
      //   displayinaddForm: "false",
      //   displayineditForm: "true",
      //   displayinlist: "true",
      //   isFieldRequired: "false",
      //   required: false,
      //   displayOptionsInActions: true,
      //   globalSearchField: "true",
      //   controllerId: null,
      //   options: [
      //     { label: "Pending", value: "Pending", color: "warning" },
      //     { label: "Active", value: "Active", color: "success" },
      //     { label: "Inactive", value: "Inactive", color: "info" },
      //     { label: "Completed", value: "Completed", color: "dark" },
      //   ],
      //   fieldType: "dropDown",
      //   filterElement: [
      //     { label: "Pending", value: "Pending", color: "warning" },
      //     { label: "Active", value: "Active", color: "success" },
      //     { label: "Inactive", value: "Inactive", color: "info" },
      //     { label: "Completed", value: "Completed", color: "dark" },
      //   ],
      //   defaultValues: ['Pending', 'Active'],
      //   dependent: [],
      //   textAlign: "Center",
      //   show: true,
      //   field: "status",
      //   showOrHideFields: [],
      //   mobile: true,
      //   displayInSettings: true,
      //   tDisplay: true,
      // },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "false",
        displayineditForm: "false",
        mobile: true,
        tDisplay: true,
      },
      {
        name: "taskID",
        fieldName: "taskID",
        type: "uniqueID",
        placeholder: "Task ID",
        value: "",
        label: "Task ID",
        width: "120px",
        addFormOrder: 18,
        editFormOrder: 18,
        header: "Task ID",
        derivedValue:
          "taskID=taskID=taskID=taskID=task_id=task_id=task_id=task_id=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "taskID",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: false,
        uniqueIdLength: "6",
        textAlign: "center",
        show: true,
        field: "taskID",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "taskStatus",
        fieldName: "taskStatus",
        type: "dropDown",
        placeholder: "TaskStatus",
        value: "New",
        label: "TaskStatus",
        width: "130px",
        addFormOrder: 19,
        editFormOrder: 19,
        header: "TaskStatus",
        derivedValue:
          "taskStatus=taskStatus=taskStatus=taskStatus=taskStatus=taskStatus=taskStatus=taskStatus=taskStatus=taskStatus=taskStatus=task_status=task_status=task_status=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "taskStatus",
        displayinaddForm: "false",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          { label: "New", value: "new", color: "#326fd1" },
          { label: "In Development", value: "in development", color: "#9fa9b7" },
          { label: "Dev Completed", value: "dev completed", color: "#198754" },
          { label: "Analysis/Design", value: "analysis/design", color: "#00FF00" },
          { label: "Review Completed", value: "review completed", color: "dark" },
          { label: "Ready For Testing", value: "ready for testing", color: "#eec137", },
          { label: "Need Clarification", value: "need clarification", color: "#FF00FF" },
          { label: "Reopen", value: "reopen", color: "red" },
          { label: "Resolved", value: "resolved", color: "#0dcaf0" },
          { label: "Moved to Prod", value: "moved to prod", color: "#00FFFF" },
          { label: "On Hold", value: "onhold", color: "#000000" },
          { label: "Future Implementation", value: "future implementation", color: "#000080" },
          { label: "Unable to Replicate", value: "unable to replicate", color: "#A52A2A" },
          { label: "Duplicate", value: "duplicate", color: "#808080" },
          { label: "Not an Issue", value: "not an issue", color: "#FFA500" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "New", value: "new", color: "primary" },
          { label: "In Development", value: "in development", color: "secondary" },
          { label: "Dev Completed", value: "dev completed", color: "success" },
          { label: "Analysis/Design", value: "analysis/design", color: "primary" },
          { label: "Review Completed", value: "review completed", color: "dark" },
          { label: "Ready For Testing", value: "ready for testing", color: "warning", },
          { label: "Need Clarification", value: "need clarification", color: "dark" },
          { label: "Reopen", value: "reopen", color: "danger" },
          { label: "Resolved", value: "resolved", color: "info" },
          { label: "Moved to Prod", value: "moved to prod", color: "primary" },
          { label: "On Hold", value: "onhold", color: "primary" },
          { label: "Future Implementation", value: "future implementation", color: "primary" },
          { label: "Unable to Replicate", value: "unable to replicate", color: "primary" },
          { label: "Duplicate", value: "duplicate", color: "primary" },
          { label: "Not an Issue", value: "not an issue", color: "primary" },
        ],
        defaultValues: ['new', 'in development', 'dev completed', 'ready for testing', 'analysis/design', 'resolved', 'reopen', 'onhold', 'future implementation', 'moved to prod', 'duplicate', 'unable to replicate', 'review completed', 'need clarification', 'not an issue'],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "taskStatus",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "screen",
        fieldName: "screen",
        type: "text",
        placeholder: "Screen",
        value: "",
        label: "Screen",
        width: "130px",
        addFormOrder: 5,
        editFormOrder: 4,
        header: "Screen Name",
        derivedValue: "screen=screen=screen=screen=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "screen",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "screen",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "startDate",
        fieldName: "startDate",
        type: "date",
        placeholder: "StartDate",
        value: "",
        label: "StartDate",
        width: "130px",
        addFormOrder: 13,
        editFormOrder: 13,
        header: "StartDate",
        derivedValue: "startDate=startDate=startDate=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "startDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "startDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "endDate",
        fieldName: "endDate",
        type: "date",
        placeholder: "endDate",
        value: "",
        label: "EndDate",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "EndDate",
        derivedValue: "endDate=endDate=endDate=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "endDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "endDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "estimatedHours",
        fieldName: "estimatedHours",
        type: "number",
        placeholder: "EstimatedHours",
        value: "",
        label: "EstimatedHours",
        width: "120px",
        addFormOrder: 9,
        editFormOrder: 9,
        header: "EstimatedHours",
        derivedValue: "estimatedHours=estimatedHours=estimatedHours=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "estimatedHours",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "estimatedHours",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "image",
        fieldName: "image",
        type: "fileUpload",
        placeholder: "image",
        value: "",
        label: "Attach File",
        width: "120px",
        addFormOrder: 14,
        editFormOrder: 14,
        header: "Attach File",
        derivedValue:
          "attatchFile=attatchFile=image=image=image=file=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "image",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: false,
        isAddToIndex: "",
        isBulkUploadField: false,
        fieldType: "fileUpload",
        fileType: "img/video",
        maxFileSize: 5,
        isMultipleRequired: true,
        imagePath: "Task",
        textAlign: "center",
        show: true,
        field: "image",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "actualWorkingHours",
        fieldName: "actualWorkingHours",
        type: "number",
        placeholder: "WorkingHours",
        value: "",
        label: "Worked Hours",
        width: "120px",
        addFormOrder: 17,
        editFormOrder: 17,
        header: "Worked Hours",
        derivedValue:
          "actualWorkingHours=actualWorkingHours=actualWorkingHours=workedHours=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "actualWorkingHours",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "actualWorkingHours",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "description",
        fieldName: "description",
        type: "ckeditor",
        placeholder: "Description",
        value: "",
        label: "Description",
        width: "200px",
        addFormOrder: 11,
        editFormOrder: 20,
        header: "Description",
        derivedValue: "description=description=description=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "description",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        fieldType: "ckeditor",
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        style: { width: '350px', textOverflow: "ellipsis" },
        show: true,
        field: "description",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "sprint",
        fieldName: "sprint",
        type: "relateAutoComplete",
        placeholder: "Sprint Name",
        value: "",
        label: "Sprint Name",
        width: "150px",
        addFormOrder: 23,
        editFormOrder: 23,
        header: "Sprint Name",
        derivedValue:
          "sprint=sprint=sprint=sprint=sprint=sprint=sprint=sprint=sprintName=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "sprint",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1035,
        isClickable: false,
        isAddToIndex: "",
        isBulkUploadField: false,
        hasDependency: "false",
        searchField: "name",
        fieldType: "relateAutoComplete",
        displayFields: [],
        controllerName: "sprint",
        searchApi: "sprints",
        // isMultiple: true,
        textAlign: "center",
        show: true,
        field: "sprint",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "meetingDate",
        fieldName: "meetingDate",
        type: "relateAutoComplete",
        placeholder: "Meeting Date",
        value: "",
        label: "Meeting Date",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Meeting Date",
        derivedValue: "meetingDate=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "meetingDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: 1007,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        searchField: "meetingDate",
        fieldType: "relateAutoComplete",
        populteFields: [],
        controllerName: "Project",
        searchApi: "projects",
        textAlign: "center",
        show: false,
        field: "meetingDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "reopenComments",
        fieldName: "reopenComments",
        type: "textarea",
        placeholder: "Reopen Comments",
        value: "",
        label: "Reopen Comments",
        width: "200px",
        addFormOrder: 27,
        editFormOrder: 32,
        header: "Reopen Comments",
        derivedValue:
          "reopenComments=reopenComments=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "reopenComments",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "reopenComments",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "taskCompletionPercent",
        type: "number",
        placeholder: "taskCompletionPercent",
        label: "Percentage of Task Completed",
        width: "120px",
        addFormOrder: 9,
        editFormOrder: 9,
        header: "Percentage of Task Completed",
        derivedValue: "taskCompletionPercent=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "taskCompletionPercent",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "taskCompletionPercent",
        showOrHideFields: [],
        fieldName: "taskCompletionPercent",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
    ];
    return data;
  };

  const getFormFields = () => {
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    return [
      {
        name: "projectId",
        fieldName: "projectId",
        type: "relateAutoComplete",
        placeholder: "Project name",
        value: meetingNotes?.projectData ? meetingNotes.projectData : "",
        label: "Project",
        width: "150px",
        addFormOrder: 1,
        editFormOrder: 2,
        derivedValue:
          "projectName=projectName=projectName=projectName=projectName=projects=Projects=Projects=Projects=Projects=Projects=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "projectId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: 1007,
        isClickable: false,
        isAddToIndex: "",
        isBulkUploadField: false,
        hasDependency: "false",
        searchField: "name",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["ProjectName"],
        controllerName: "Project",
        searchApi: "projects",
        filterCriteria: { key: 'status', value: 'Active', type: 'eq' },
        isMultiple: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        isEditFieldDisable: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        dependent: { key: 'showsTaskId', value: 'Yes', field: 'taskID' }
      },
      {
        name: "name",
        fieldName: "name",
        type: "text",
        placeholder: "Task Summary",
        value: "",
        label: "Task Summary",
        width: "130px",
        addFormOrder: 5,
        editFormOrder: 5,
        derivedValue:
          "name=name=name=taskSummary=TaskSummary=TaskSummary=TaskSummary=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: true,
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#f17038",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "priority",
        fieldName: "priority",
        type: "dropDown",
        placeholder: "Priority",
        value: "",
        label: "Priority",
        width: "130px",
        addFormOrder: 7,
        editFormOrder: 7,
        derivedValue:
          "priority=priority=priority=priority=priority=priority=Priority=Priority=Priority=undefined",
        capitalizeTableText: true,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "priority",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          { label: "Blocker", value: "blocker", color: "primary" },
          { label: "Major", value: "major", color: "primary" },
          { label: "Minor", value: "minor", color: "primary" },
          { label: "Critical", value: "critical", color: "primary" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "Blocker", value: "Blocker", color: "primary" },
          { label: "Major", value: "Major", color: "primary" },
          { label: "Minor", value: "Minor", color: "primary" },
          { label: "Critical", value: "Critical", color: "primary" },
        ],
        // dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "priorityNumber",
        type: "number",
        placeholder: "priorityNumber",
        label: "Priority Number",
        width: "120px",
        addFormOrder: 8,
        editFormOrder: 8,
        derivedValue: "priorityNumber=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "priorityNumber",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        fieldName: "priorityNumber",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "linkedTask",
        type: "relateAutoComplete",
        placeholder: "Linked Task",
        label: "Linked Task",
        width: "150px",
        addFormOrder: 2,
        editFormOrder: 3,
        derivedValue:
          "projectName=projects=Projects=Projects=Projects=Projects=Projects=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        actions: [],
        actionsNumber: [],
        id: "linkedTask",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: "Project",
        searchField: "name",
        fieldType: "relateAutoComplete",
        populteFields: [],
        controllerName: "Project",
        searchApi: "tasks",
        filterCriteria: { key: 'status', value: 'Active', type: 'eq' },
        isMultiple: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        dependent: { key: 'showsTaskId', value: 'Yes', field: 'taskID' },
        templateField: 'taskID',
        hasDependency: true,
        fieldForKey: "projectId",
        hasDependencyField: "projectId",
        isShowItemTemplate: true,
      },
      {
        name: "type",
        fieldName: "type",
        type: "dropDown",
        placeholder: "Type",
        value: "new",
        label: "Type",
        width: "130px",
        addFormOrder: 14,
        editFormOrder: 14,
        derivedValue: "type=type=type=type=type=Type=Type=Type=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "type",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          { label: "New", value: "new", color: "primary" },
          { label: "Defect", value: "defect", color: "primary" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "New", value: "new", color: "primary" },
          { label: "Defect", value: "defect", color: "primary" },
        ],
        // dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "assignedTo",
        fieldName: "assignedTo",
        type: "dropDown",
        placeholder: "Assigned To",
        value: meetingNotes && meetingNotes.projectData && meetingNotes.projectData.teamLead ? meetingNotes.projectData.teamLead : "",
        label: "Assigned To",
        width: "150px",
        addFormOrder: 13,
        editFormOrder: 13,
        derivedValue: "assignedTo=assignedTo=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "assignedTo",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1001,
        isClickable: false,
        isAddToIndex: "",
        isBulkUploadField: false,
        hasDependencyField: "employee",
        searchField: "displayName",
        fieldType: "dropDown",
        // populteFields: [],
        // displayFields: ["name"],
        // controllerName: "employee",
        // searchApi: "employees",
        isMultiple: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "taskID",
        fieldName: "taskID",
        type: "uniqueID",
        placeholder: "Task ID",
        value: "",
        label: "Task ID",
        width: "120px",
        addFormOrder: 3,
        editFormOrder: 1,
        derivedValue:
          "taskID=taskID=taskID=taskID=task_id=task_id=task_id=task_id=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "taskID",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: false,
        uniqueIdLength: "6",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFieldDisable: false,
        isEditFieldDisable: true,
        isAddFormHidden: true,
        isEditFormHidden: false,
      },
      {
        name: "taskStatus",
        fieldName: "taskStatus",
        type: "dropDown",
        placeholder: "TaskStatus",
        value: "New",
        label: "TaskStatus",
        width: "130px",
        addFormOrder: 9,
        editFormOrder: 9,
        derivedValue:
          "taskStatus=taskStatus=taskStatus=taskStatus=taskStatus=taskStatus=taskStatus=taskStatus=taskStatus=taskStatus=taskStatus=task_status=task_status=task_status=undefined",
        capitalizeTableText: true,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "taskStatus",
        displayinaddForm: "false",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          { label: "New", value: "new", color: "primary" },
          { label: "In Development", value: "in development", color: "secondary" },
          { label: "Dev Completed", value: "dev completed", color: "success" },
          { label: "Analysis/Design", value: "analysis/design", color: "primary" },
          { label: "Review Completed", value: "review completed", color: "light" },
          { label: "Need Clarification", value: "need clarification", color: "dark" },
          { label: "Reopen", value: "reopen", color: "danger" },
          { label: "Resolved", value: "resolved", color: "info" },
          { label: "Moved to Prod", value: "moved to prod", color: "primary" },
          { label: "On Hold", value: "onhold", color: "primary" },
          { label: "Future Implementation", value: "future implementation", color: "primary" },
          { label: "Unable to Replicate", value: "Unable to Replicate", color: "primary" },
          { label: "Duplicate", value: "duplicate", color: "primary" },
          { label: "Not an Issue", value: "not an issue", color: "primary" },
        ],
        altOptions: [
          { label: "In Development", value: "in development", color: "secondary" },
          { label: "Dev Completed", value: "dev completed", color: "success" },
          { label: "Review Completed", value: "review completed", color: "light" },
          { label: "Ready For Testing", value: "ready for testing", color: "warning", },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "New", value: "new", color: "primary" },
          { label: "In Development", value: "in development", color: "secondary" },
          { label: "Dev Completed", value: "dev completed", color: "success" },
          { label: "Analysis/Design", value: "analysis/design", color: "primary" },
          { label: "Review Completed", value: "review completed", color: "light" },
          { label: "Ready For Testing", value: "ready for testing", color: "warning", },
          { label: "Need Clarification", value: "need clarification", color: "dark" },
          { label: "Reopen", value: "reopen", color: "danger" },
          { label: "Resolved", value: "resolved", color: "info" },
          { label: "Moved to Prod", value: "moved to prod", color: "primary" },
          { label: "On Hold", value: "onhold", color: "primary" },
          { label: "Future Implementation", value: "future implementation", color: "primary" },
          { label: "Unable to Replicate", value: "Unable to Replicate", color: "primary" },
          { label: "Duplicate", value: "duplicate", color: "primary" },
          { label: "Not an Issue", value: "not an issue", color: "primary" },
        ],
        dependent: [{ "reopen": ["reopenComments"] }],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: false,
      },
      {
        name: "screen",
        fieldName: "screen",
        type: "text",
        placeholder: "Screen",
        value: "",
        label: "Screen/Module Name",
        width: "130px",
        addFormOrder: 6,
        editFormOrder: 6,
        derivedValue: "screen=undefined",
        capitalizeTableText: true,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "screen",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "startDate",
        fieldName: "startDate",
        type: "date",
        placeholder: "StartDate",
        value: "",
        label: "Start Date",
        width: "130px",
        addFormOrder: 10,
        editFormOrder: 10,
        derivedValue: "startDate=startDate=startDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "startDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "endDate",
        fieldName: "endDate",
        type: "date",
        placeholder: "endDate",
        value: "",
        label: "End Date",
        width: "130px",
        addFormOrder: 11,
        editFormOrder: 11,
        derivedValue: "endDate=endDate=endDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "endDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "estimatedHours",
        fieldName: "estimatedHours",
        type: "number",
        placeholder: "EstimatedHours",
        value: "",
        label: "Estimated Hours",
        width: "120px",
        addFormOrder: 12,
        editFormOrder: 12,
        derivedValue: "estimatedHours=estimatedHours=estimatedHours=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "estimatedHours",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "image",
        fieldName: "image",
        type: "fileUpload",
        placeholder: "Attatch File",
        value: "",
        label: "Attach File",
        width: "120px",
        addFormOrder: 18,
        editFormOrder: 18,
        derivedValue:
          "attatchFile=attatchFile=image=image=image=file=undefined",
        capitalizeTableText: true,
        sortable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "image",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        fieldType: "fileUpload",
        fileType: "img/video",
        maxFileSize: 100,
        isMultipleRequired: true,
        imagePath: "Task",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "actualWorkingHours",
        fieldName: "actualWorkingHours",
        type: "number",
        placeholder: "Worked Hours",
        value: "",
        label: "Worked Hours",
        width: "120px",
        addFormOrder: 19,
        editFormOrder: 19,
        derivedValue:
          "actualWorkingHours=actualWorkingHours=actualWorkingHours=workedHours=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "actualWorkingHours",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        isEditFieldDisable: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: false,
      },
      {
        name: "description",
        fieldName: "description",
        type: "ckeditor",
        placeholder: "Description",
        value: meetingNotes && meetingNotes.projectNotes ? meetingNotes.projectNotes : "",
        label: "Description",
        width: "200px",
        addFormOrder: 20,
        editFormOrder: 20,
        derivedValue: "description=description=description=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "description",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "sprint",
        fieldName: "sprint",
        type: "relateAutoComplete",
        placeholder: "Sprint Name",
        value: "",
        label: "Sprint Name",
        width: "150px",
        addFormOrder: 4,
        editFormOrder: 4,
        derivedValue:
          "sprint=sprint=sprint=sprint=sprint=sprint=sprint=sprintName=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "sprint",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: 1035,
        isClickable: false,
        isAddToIndex: "",
        isBulkUploadField: false,
        hasDependency: "true",
        searchField: "name",
        fieldType: "relateAutoComplete",
        displayFields: [],
        controllerName: "sprint",
        filterCriteria: { key: 'status', value: ['Not Started', 'In Progress', 'Dev Completed'], type: 'in' },
        searchApi: "sprints",
        isMultiple: false,
        hasDependencyField: "projectId",
        fieldForKey: "projectId",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "reopenComments",
        fieldName: "reopenComments",
        type: "textarea",
        placeholder: "Reopen Comments",
        value: "",
        label: "Reopen Comments",
        width: "120px",
        addFormOrder: 15,
        editFormOrder: 15,
        header: "Reopen Comments",
        derivedValue: "createdByName=createdByName=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "reopenComments",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: 1008,
        isClickable: "",
        textAlign: "Center",
        style: { width: '250px', textOverflow: "ellipsis" },
        isAddToIndex: "",
        isBulkUploadField: false,
        show: true,
        field: "reopenComments",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: false,
      },
      {
        name: "meetingDate",
        fieldName: "meetingDate",
        type: "relateAutoComplete",
        placeholder: "Meeting Date",
        value: meetingNotes && meetingNotes.meetingDate ? meetingNotes.meetingDate : "",
        label: "Meeting Date",
        width: "120px",
        addFormOrder: 16,
        editFormOrder: 16,
        stringType: null,
        derivedValue: "meetingDate=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "meetingDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: 1007,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        searchField: "meetingDate",
        fieldType: "relateAutoComplete",
        populteFields: [],
        controllerName: "Project",
        searchApi: "projects",
        filterCriteria: { key: 'status', value: 'Active', type: 'eq' },
        hasDependencyField: "projectId",
        fieldForKey: "_id",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "taskCompletionPercent",
        type: "number",
        placeholder: "Percentage of Task Completed",
        label: "Percentage of Task Completed",
        width: "120px",
        addFormOrder: 17,
        editFormOrder: 17,
        derivedValue: "taskCompletionPercent=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "taskCompletionPercent",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        fieldName: "taskCompletionPercent",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      }
    ];
  };

  const submit = async (item) => {
    setIsOpenFormModal(true);
    // await this.formModalRef.getRowData(item, 'edit');
    console.log("Submit Button in sode          ");
  };

  const getMobileTableFields = () => {

    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "S.No",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        fieldName: "createdByName",
        type: "Text",
        placeholder: "Created By",
        value: "",
        label: "Created By",
        width: "120px",
        header: "Created By",
        derivedValue: "createdByName=createdByName=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "createdByName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: 1001,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        fieldType: "Text",
        textAlign: "center",
        show: true,
        field: "createdByName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        fieldName: "created",
        type: "date",
        placeholder: "Created",
        value: "",
        label: "Created",
        width: 90,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Created",
        derivedValue: "created=created=created=created=undefined",
        capitalizeTableText: "",
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: true,
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "created",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        fieldName: "updated",
        type: "date",
        placeholder: "Updated",
        value: "",
        label: "Updated",
        width: 90,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Updated",
        derivedValue: "updated=updated=updated=undefined",
        capitalizeTableText: "",
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "updated",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "projectName",
        fieldName: "projectName",
        type: "relateAutoComplete",
        placeholder: "Project name",
        value: "",
        label: "Project",
        width: "150px",
        addFormOrder: 1,
        editFormOrder: 1,
        header: "Project",
        derivedValue:
          "projectName=projectName=projectName=projectName=projectName=projects=Projects=Projects=Projects=Projects=Projects=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "projectName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1007,
        isClickable: false,
        isAddToIndex: "",
        isBulkUploadField: false,
        hasDependency: "false",
        searchField: "name",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["ProjectName"],
        controllerName: "Project",
        searchApi: "projects",
        isMultiple: false,
        textAlign: "center",
        show: true,
        field: "projectId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "name",
        fieldName: "taskSummary",
        type: "text",
        placeholder: "Task Summary",
        value: "",
        label: "Task Summary",
        width: "130px",
        addFormOrder: 1,
        editFormOrder: 8,
        header: "Task Summary",
        derivedValue:
          "taskSummary=taskSummary=taskSummary=taskSummary=TaskSummary=TaskSummary=TaskSummary=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "taskSummary",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: true,
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "left",
        show: true,
        field: "name",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: { width: '300px', textOverflow: "ellipsis" },
        tDisplay: true,
      },
      {
        name: "priority",
        fieldName: "priority",
        type: "dropDown",
        placeholder: "Priority",
        value: "",
        label: "Priority",
        width: "130px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "Priority",
        derivedValue:
          "priority=priority=priority=priority=priority=priority=Priority=Priority=Priority=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "priority",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          { label: "Blocker", value: "blocker", color: "warning" },
          { label: "Major", value: "major", color: "success" },
          { label: "Minor", value: "minor", color: "primary" },
          { label: "Critical", value: "critical", color: "danger" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "Blocker", value: "blocker", color: "primary" },
          { label: "Major", value: "major", color: "primary" },
          { label: "Minor", value: "minor", color: "primary" },
          { label: "Critical", value: "critical", color: "primary" },
        ],
        defaultValues: ['critical', 'major', 'minor', 'blocker'],
        // dependent: [],
        textAlign: "center",
        show: true,
        field: "priority",
        showOrHideFields: [],
        fieldName: "priority",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },

      {
        name: "priorityNumber",
        type: "number",
        placeholder: "priorityNumber",
        label: "Priority Number",
        width: "120px",
        addFormOrder: 9,
        editFormOrder: 9,
        header: "Priority Number",
        derivedValue: "priorityNumber=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "priorityNumber",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "priorityNumber",
        showOrHideFields: [],
        fieldName: "priorityNumber",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "type",
        fieldName: "type",
        type: "dropDown",
        placeholder: "Type",
        value: "New",
        label: "Type",
        width: "130px",
        addFormOrder: 12,
        editFormOrder: 12,
        header: "Type",
        derivedValue: "type=type=type=type=type=type=Type=Type=Type=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "type",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          { label: "New", value: "new", color: "primary" },
          { label: "Defect", value: "defect", color: "secondary" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "New", value: "new", color: "primary" },
          { label: "Defect", value: "defect", color: "secondary" },
        ],
        defaultValues: ['new', 'defect'],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "type",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "assignedTo",
        fieldName: "assignedTo",
        type: "dropDown",
        placeholder: "Assigned To",
        value: "",
        label: "Assigned To",
        width: "150px",
        addFormOrder: 15,
        editFormOrder: 15,
        header: "Assigned To",
        derivedValue:
          "assignedTo=assignedTo=assignedTo=assignedTo=assignedTo=assignedTo=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "assignedTo",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1001,
        isClickable: false,
        isAddToIndex: "",
        isBulkUploadField: false,
        hasDependency: "false",
        searchField: "displayName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["name"],
        controllerName: "employee",
        searchApi: "employees",
        isMultiple: false,
        textAlign: "center",
        show: true,
        field: "assignedTo",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "status",
        type: "dropDown",
        placeholder: "Status",
        label: "Status",
        width: "130px",
        addFormOrder: 11,
        editFormOrder: 11,
        header: "Status",
        derivedValue:
          "status=Status=Status=Status=Status=Status=Status=Status=Status=Status=Status=Status=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "status",
        displayinaddForm: "false",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: true,
        globalSearchField: "true",
        controllerId: null,
        options: [
          { label: "Pending", value: "Pending", color: "warning" },
          { label: "Active", value: "Active", color: "success" },
          { label: "Inactive", value: "Inactive", color: "info" },
          { label: "Completed", value: "Completed", color: "dark" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "Pending", value: "Pending", color: "warning" },
          { label: "Active", value: "Active", color: "success" },
          { label: "Inactive", value: "Inactive", color: "info" },
          { label: "Completed", value: "Completed", color: "dark" },
        ],
        defaultValues: ['Pending', 'Active'],
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "status",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "false",
        displayineditForm: "false",
        mobile: true,
        tDisplay: true,
      },
      {
        name: "taskID",
        fieldName: "taskID",
        type: "uniqueID",
        placeholder: "Task ID",
        value: "",
        label: "Task ID",
        width: "120px",
        addFormOrder: 18,
        editFormOrder: 18,
        header: "Task ID",
        derivedValue:
          "taskID=taskID=taskID=taskID=task_id=task_id=task_id=task_id=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "taskID",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: false,
        uniqueIdLength: "6",
        textAlign: "center",
        show: true,
        field: "taskID",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "taskStatus",
        fieldName: "taskStatus",
        type: "dropDown",
        placeholder: "TaskStatus",
        value: "New",
        label: "TaskStatus",
        width: "130px",
        addFormOrder: 19,
        editFormOrder: 19,
        header: "TaskStatus",
        derivedValue:
          "taskStatus=taskStatus=taskStatus=taskStatus=taskStatus=taskStatus=taskStatus=taskStatus=taskStatus=taskStatus=taskStatus=task_status=task_status=task_status=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "taskStatus",
        displayinaddForm: "false",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          { label: "New", value: "new", color: "#326fd1" },
          { label: "In Development", value: "in development", color: "#9fa9b7" },
          { label: "Dev Completed", value: "dev completed", color: "#198754" },
          { label: "Analysis/Design", value: "analysis/design", color: "#00FF00" },
          { label: "Review Completed", value: "review completed", color: "dark" },
          { label: "Ready For Testing", value: "ready for testing", color: "#eec137", },
          { label: "Need Clarification", value: "need clarification", color: "#FF00FF" },
          { label: "Reopen", value: "reopen", color: "red" },
          { label: "Resolved", value: "resolved", color: "#0dcaf0" },
          { label: "Moved to Prod", value: "moved to prod", color: "#00FFFF" },
          { label: "On Hold", value: "onhold", color: "#000000" },
          { label: "Future Implementation", value: "future implementation", color: "#000080" },
          { label: "Unable to Replicate", value: "unable to replicate", color: "#A52A2A" },
          { label: "Duplicate", value: "duplicate", color: "#808080" },
          { label: "Not an Issue", value: "not an issue", color: "#FFA500" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "New", value: "new", color: "primary" },
          { label: "In Development", value: "in development", color: "secondary" },
          { label: "Dev Completed", value: "dev completed", color: "success" },
          { label: "Analysis/Design", value: "analysis/design", color: "primary" },
          { label: "Review Completed", value: "review completed", color: "dark" },
          { label: "Ready For Testing", value: "ready for testing", color: "warning", },
          { label: "Need Clarification", value: "need clarification", color: "dark" },
          { label: "Reopen", value: "reopen", color: "danger" },
          { label: "Resolved", value: "resolved", color: "info" },
          { label: "Moved to Prod", value: "moved to prod", color: "primary" },
          { label: "On Hold", value: "onhold", color: "primary" },
          { label: "Future Implementation", value: "future implementation", color: "primary" },
          { label: "Unable to Replicate", value: "unable to replicate", color: "primary" },
          { label: "Duplicate", value: "duplicate", color: "primary" },
          { label: "Not an Issue", value: "not an issue", color: "primary" },
        ],
        defaultValues: ['new', 'in development', 'dev completed', 'ready for testing', 'analysis/design', 'resolved', 'reopen', 'onhold', 'future implementation', 'moved to prod', 'duplicate', 'unable to replicate', 'review completed', 'need clarification', 'not an issue'],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "taskStatus",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "screen",
        fieldName: "screen",
        type: "text",
        placeholder: "Screen",
        value: "",
        label: "Screen",
        width: "130px",
        addFormOrder: 5,
        editFormOrder: 4,
        header: "Screen Name",
        derivedValue: "screen=screen=screen=screen=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "screen",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "screen",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "startDate",
        fieldName: "startDate",
        type: "date",
        placeholder: "StartDate",
        value: "",
        label: "StartDate",
        width: "130px",
        addFormOrder: 13,
        editFormOrder: 13,
        header: "StartDate",
        derivedValue: "startDate=startDate=startDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "startDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "startDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "endDate",
        fieldName: "endDate",
        type: "date",
        placeholder: "endDate",
        value: "",
        label: "EndDate",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "EndDate",
        derivedValue: "endDate=endDate=endDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "endDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "endDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "estimatedHours",
        fieldName: "estimatedHours",
        type: "number",
        placeholder: "EstimatedHours",
        value: "",
        label: "EstimatedHours",
        width: "120px",
        addFormOrder: 9,
        editFormOrder: 9,
        header: "EstimatedHours",
        derivedValue: "estimatedHours=estimatedHours=estimatedHours=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "estimatedHours",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "estimatedHours",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "image",
        fieldName: "image",
        type: "fileUpload",
        placeholder: "image",
        value: "",
        label: "Attach File",
        width: "120px",
        addFormOrder: 14,
        editFormOrder: 14,
        header: "Attach File",
        derivedValue:
          "attatchFile=attatchFile=image=image=image=file=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "image",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: false,
        isAddToIndex: "",
        isBulkUploadField: false,
        fieldType: "fileUpload",
        fileType: "img/video",
        maxFileSize: 5,
        isMultipleRequired: true,
        imagePath: "Task",
        textAlign: "center",
        show: true,
        field: "attatchFile",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "actualWorkingHours",
        fieldName: "actualWorkingHours",
        type: "number",
        placeholder: "WorkingHours",
        value: "",
        label: "Worked Hours",
        width: "120px",
        addFormOrder: 17,
        editFormOrder: 17,
        header: "Worked Hours",
        derivedValue:
          "actualWorkingHours=actualWorkingHours=actualWorkingHours=workedHours=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "actualWorkingHours",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "actualWorkingHours",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "description",
        fieldName: "description",
        type: "ckeditor",
        placeholder: "Description",
        value: "",
        label: "Description",
        width: "200px",
        addFormOrder: 11,
        editFormOrder: 20,
        header: "Description",
        derivedValue: "description=description=description=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "description",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        fieldType: "ckeditor",
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        style: { width: '350px', textOverflow: "ellipsis" },
        show: true,
        field: "description",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "sprint",
        fieldName: "sprint",
        type: "relateAutoComplete",
        placeholder: "Sprint Name",
        value: "",
        label: "Sprint Name",
        width: "150px",
        addFormOrder: 23,
        editFormOrder: 23,
        header: "Sprint Name",
        derivedValue:
          "sprint=sprint=sprint=sprint=sprint=sprint=sprint=sprint=sprintName=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "sprint",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1035,
        isClickable: false,
        isAddToIndex: "",
        isBulkUploadField: false,
        hasDependency: "false",
        searchField: "name",
        fieldType: "relateAutoComplete",
        displayFields: [],
        controllerName: "sprint",
        searchApi: "sprints",
        // isMultiple: true,
        textAlign: "center",
        show: true,
        field: "sprint",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
    ];
    return data;
  };
  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };

  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    // window.location.reload();
    if (dataTableRef.current) {
      const { getDataFromServer, filterCriteria } = await dataTableRef.current;
      getDataFromServer(filterCriteria);
    }
  };

  const saveDataToServer = async (item, field, value) => {
    const { getDataFromServer } = await dataTableRef.current;
    let userBody;
    if (item?.length > 0 && field && value) {
      let selectedIds = item.map((row) => row._id);
      userBody = {
        selectedIds: selectedIds,
        updatedDetails: { [field]: value },
      };
    } else {
      return;
    }
    if (userBody) {
      let method, apiUrl;
      method = "POST";
      apiUrl = `${apiCalls.tasks}/multiUpdate`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          if (response && response.respCode) {
            showToasterMessage(response.respMessage, "success");
            getDataFromServer();
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };

  const isEditPermission = rolePermission?.type == "Edit" ? true : false;
  const actionTypes = [
    {
      name: "Delete",
      options: [
        {
          label: "Delete",
          value: "Delete",
          show: rolePermission?.actions?.includes("Delete") ? true : false,
          multiple: true,
        },
      ],
    },
    {
      name: "dev completed",
      action: saveDataToServer,
      options: [
        {
          label: "Dev Completed",
          field: "taskStatus",
          value: "dev completed",
          show: rolePermission?.actions?.includes("Approve/Reject") ? true : false,
          multiple: true,
        },
      ],
    },
    {
      name: "review completed",
      action: saveDataToServer,
      options: [
        {
          label: "Review Completed",
          field: "taskStatus",
          value: "review completed",
          show: rolePermission?.actions?.includes("Approve/Reject") ? true : false,
          multiple: true,
        },
      ],
    },
    {
      name: "ready for testing",
      action: saveDataToServer,
      options: [
        {
          label: "Ready For Testing",
          field: "taskStatus",
          value: "ready for testing",
          show: rolePermission?.actions?.includes("Approve/Reject") ? true : false,
          multiple: true,
        },
      ],
    },
    {
      name: "resolved",
      action: saveDataToServer,
      options: [
        {
          label: "Resolved",
          field: "taskStatus",
          value: "resolved",
          show: rolePermission?.actions?.includes("Approve/Reject") ? true : false,
          multiple: true,
        },
      ],
    },
    {
      name: "moved to prod",
      action: saveDataToServer,
      options: [
        {
          label: "Moved to Prod",
          field: "taskStatus",
          value: "moved to prod",
          show: rolePermission?.actions?.includes("Approve/Reject") ? true : false,
          multiple: true,
        },
      ],
    }
  ]

  return (
    <span>
      {
        <DataTables
          onRef={(ref) => (dataTableRef.current = ref)}
          {...props}
          getTableFields={getTableFields}
          formFields={getFormFields}
          //
          addRequired={isEditPermission}
          editRequired={isEditPermission}
          deleteRequired={isEditPermission}
          viewRequired={isEditPermission}
          exportRequired={isEditPermission}
          sample={true}
          // globalSearch={'Display Name/Email'}
          // type='Tasks'
          // apiUrl={apiCalls.Tasks}
          // exportRequried={true}
          printRequried={true}
          actionsTypes={actionTypes}
          // addRequried={insertAdd}
          // editRequired={true}
          // deleteRequired={true}
          // viewRequired={true}
          settingsRequired={true}
          filterRequired={false}
          gridRequried={true}
          exportToCsv={true}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          sampleFilePath={filePath.tasks}
          globalSearch={
            "taskSummary/priority/type/status/taskStatus/screen/description"
          }
          displayName="Tasks"
          type="Tasks"
          routeTo={apiCalls.tasks}
          displayViewOfForm="sideForm"
          sideFormLeftOrRight="right"
          apiResponseKey={apiCalls.tasks?.toLowerCase()}
          apiUrl={apiCalls.tasks}
          selectedId={params.id}
          displayActionsInTable={"actions"}
          meetingNotes={meetingNotes}
        />
      }
      {isOpenFormModal ? (
        <FormModal
          // onRef={(ref) => (this.formModalRef = ref)}
          formType="edit"
          openFormModal={isOpenFormModal}
          formFields={formFields}
        />
      ) : null}
      {openNewUserModal ? (
        <NewUserModal
          openNewUserModal={openNewUserModal}
          cancelReset={cancelReset}
          item={item}
          newFormFields={newFormFields}
          recordId={item._id}
          entityType="tasks"
          apiUrl={apiCalls.tasks}
        />
      ) : null}
    </span>
  );
};

export default Tasks;
