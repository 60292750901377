import React, { useEffect, useState, useRef } from "react";
import { AutoComplete } from 'primereact/autocomplete';
import fetch from '../../../config/service';
import config from "../../../config/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";

const DoAutoCompleteField = (props) => {
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [isValidate,setIsValidate] = useState(false);
    const [noData,setNoData] = useState(false);
    const [errorMessage,setErrorMessage] = useState('');
    const [disable, setDisable] = props.item.hasDependencyField ? useState(false) : useState(false);

    const [setNote, setNotes] = useState([]);
    const [assignedEmployees, setAssignedEmployees] = useState([]);

    const [projectID, setProjectID] = useState(props.projectID);

    let message;
    const id = props.id ? props.id : props.name;
    const label = props.label ? props.label : props.name;
    const name = props.name;
    const type = props.type ? props.type : 'text';
    let field = props.field;
    const placeholder = props.placeholder;
    const screen = props.screen;
    const login = JSON.parse(localStorage.getItem('loginCredentials'))
    const isDisable = props.isDisable ? true : false
    let parentField = props.watch && props.item.hasDependencyField ? props.watch(props.item.hasDependencyField) : '';
    let previousParentDataRef = useRef(parentField)


    const isDependencyRef = useRef(true);

    const onSelectRecord = (e) => {
        field.onChange(e);
        props.onChange(e.value, props.item);
    }

    useEffect(() => {
        if(props.item.hasDependencyField){
            if(parentField){
                // setDisable(false);
                setErrorMessage('')
                previousParentDataRef.current = parentField;
            } else {
                // setDisable(true);
            }
            if(previousParentDataRef.current?._id != parentField?._id) {
                props.setValue(props.field.name,'');
            }
        }
    }, [parentField])
    
    // const onSelectRecord = (e)=> {
    //     setNoData(false);
    //     setIsValidate(false);
    //     setErrorMessage('');
    //     if (props.input) {
    //         const { name, onChange } = this.props.input;
    //         onChange(e.value);
    //         this.props.handleAutoCompleteData(e.value, name)
    //         if (e && e.value) {
    //             this.removeDuplicates(e.value, name);
    //         }
    //     }

    // }

     const getAllSuggestions = (event, type) => {
        let url;
        setIsValidate(false);
        setNoData(false);
        setErrorMessage('');
        let limit = (screen === "Projects" || props.searchApi === 'employees') ? 50 : 30;
        let filterCriteria = {
            limit: limit,
            page: 1,
            sortfield: "created",
            direction: "desc",
            criteria: []
        };

        if(props.item.hasDependencyField){
            if(props.getValues(props.item.hasDependencyField) && props.getValues(props.item.hasDependencyField)["_id"]){
                filterCriteria['criteria'].push({
                    "key":props.item.fieldForKey,
                    "value":props.getValues(props.item.hasDependencyField)["_id"],
                    "type":"eq"
                })
            }
             else {
                setNoData(true);
                setFilteredSuggestions([]);
                // setErrorMessage('Please select the ' + props.item.hasDependencyField[0].toUpperCase() +  props.item.hasDependencyField.slice(1));
                setErrorMessage('Please Select project First');
                return
            }
        }
            //to not include the task itself under the linked tasks list
        if(props.name=="linkedTask" && screen=="Tasks"){
            filterCriteria['criteria'].push({
                "key": "_id",
                "value": props.rowData["_id"],
                "type": "ne"
            })
        }

        if (projectID) {
            filterCriteria['criteria'].push({
                "key":"projectId",
                "value":projectID,
                "type":"eq"
            })        
        }
    
        if (props.criteria) {
            filterCriteria['criteria'].push(props.criteria)
        }

        if (props.criteria2) {
            filterCriteria['criteria'].push(props.criteria2)
        }

        if (screen === 'Sprints' && name === 'projectId' && (login?.role === "Senior Developer" || login?.role === "TeamLead")) {
            let crit = {
                displayName: login?.displayName,
                _id: login?._id
            }
            filterCriteria['criteria'].push({ key: 'teamLead', value: crit , type: 'in' })
        }

        if (screen == 'Projects' && name === 'teamLead') {
            filterCriteria['criteria'].push({ key: 'roleType', value: ['Manager', 'Admin'], type: 'in' })
        }
        if (screen === 'Employees' && props.searchApi === 'employees') {
            if (props.item.name == 'hrReportingTo') {
                filterCriteria['criteria'].push({ key: 'roleType', value: 'HR', type: 'eq' })
            } else {
                filterCriteria['criteria'].push({ key: 'roleType', value: ['Manager'], type: 'in' })
            }
        }

        if (props.screensss == "feedback"){
             filterCriteria['criteria'].push({ "key": "_id", "value": props.projectttName, "type": "eq" })
        }

        if (event && event.query) {
            filterCriteria['criteria'].push({
                "key": props.searchField,
                "value": event.query,
                "type": "regexOr"
            });
        };
        let apiUrl = props.searchApi?.toLowerCase();
        url = `${apiUrl}?searchFrom=autoComplete&filter=${JSON.stringify(filterCriteria)}`;
        if (
            ((screen == 'Tasks' || screen == 'TimeSheets' || screen == "Backlogs" || screen == "Sprints" || screen === 'Active Sprints') && props.searchApi == 'projects' && props.name == "projectId") || 
            (screen == 'Tasks' && props.searchApi == 'projects' && props.name == "meetingDate") || 
            (screen == 'Releases') ||
            (props.searchApi === 'employees' && (screen === 'TimeSheets' || screen === 'Leaves') && login.roleType !== 'Admin')
        ) {
            url = `${apiUrl}?filter=${JSON.stringify(filterCriteria)}`;
        }
        return fetch('GET', url)
            .then((response) => {
                if (response) {
                    let dropdownData = [];
                    let saveEmpID = true;
                    if ((login.role === 'VP' || login.role === 'Director') && (!props.saveId?._id || (props?.saveId?._id === login?._id))){
                        saveEmpID = false
                    }

                    if (response[apiUrl] && response[apiUrl].length && response[apiUrl].length > 0) {
                        dropdownData = response[apiUrl];
                    }
                    // if (response[apiUrl] && response[apiUrl].length === 1) {
                    //     console.log("error check", props.field.name, response[apiUrl][0])
                    //     props.setValue(props.field.name, response[apiUrl][0]);
                    // }
                    if (dropdownData && dropdownData.length == 0) {                       
                        setFilteredSuggestions([]);
                        setNoData(true);
                    }

                    if (props.searchApi === 'employees' && (screen === 'TimeSheets' || screen === 'Leaves') && login.roleType !== 'Admin') {
                        //including login employee in the dropdown list
                        if (login) {
                            setFilteredSuggestions([...response.employees, login]);
                        }else{
                            setFilteredSuggestions(response.employees);
                        }
                    }
                    else if (props.searchApi === 'projects' && screen === 'TimeSheets' && login && login.roleType !== 'Employee' && saveEmpID) {
                        let identity;
                        if(props.formType === 'edit'){
                            identity = props?.rowData?.employee?._id
                        }else if(props?.savingTimesheetValues?.employee?._id){
                            identity = props?.savingTimesheetValues?.employee?._id
                        }else{
                            identity = login._id
                        }
                        let employeeID = (props.saveId?.reportingTo?._id) ? props.saveId?._id : (props?.empObjFromDashboard?._id) ? props?.empObjFromDashboard?._id : identity;
                        let employeeReporties = setProjectDropdown(response.projects, employeeID);
                        setFilteredSuggestions(employeeReporties)
                    }
                    else {
                        setSuggestions(dropdownData);
                    }
                }
            }).catch((err) => {
                return err;
            });
    }

    //Displaying employees based on reporting person
    let setEmployeeDropdown = (employees, reporteeId, selectedEmployees = []) => {

        let reportingEmployees = employees.filter(employee => employee.reportingTo?._id === reporteeId);
        selectedEmployees.push(...reportingEmployees);

        // Recursively find reporting employees for each reporting employee
        for (let employee of reportingEmployees) {
            setEmployeeDropdown(employees, employee?._id, selectedEmployees);
        }
    
        return selectedEmployees;
    };

    //Displaying projects based on selected employees fro the manager roletype
    let setProjectDropdown = (projects, employeeID) => {
        return projects.filter(project => 
            project.assignEmployees.some(employee => employee._id === employeeID) ||
            (project.teamLead && project.teamLead._id === employeeID)
        );
    };

    const setSuggestions = async (dropdownData) => {
        if (props.input && props.input.value) {
            if (props.input.value.length > 0) {
                let values = props.input.value;
                if (Array.isArray(values)) {
                    values.forEach(element => {
                        let field = '_id';
                        dropdownData = dropdownData.filter((item) => item[field] !== element[field]);
                    });
                }
            }
        }
        if(screen === "Projects" && props.name === "assignEmployees" && (props.saveTLObj?._id || props.rowData?.teamLead)){
            let dropValues = (props.saveTLObj?._id) ? props.saveTLObj : props.rowData?.teamLead;
            dropdownData = dropdownData.filter(item => item._id !== dropValues._id);
        }
        
        if(screen === "Projects" && props.name === "teamLead" && (props.saveAssignEmpObj?.length > 0)){
            let dropValues = (props.saveAssignEmpObj?.length > 0) ? props.saveAssignEmpObj : props.rowData?.assignEmployees;
            dropdownData = dropdownData.filter(
                item => !dropValues.some(savedItem => savedItem._id === item._id)
            );
        }
        setFilteredSuggestions(dropdownData);
        setAssignedEmployees(dropdownData[0]?.assignEmployees)
        
        if (screen == 'Tasks' && props.name == "meetingDate"){
            const allMeetingNotes = dropdownData.map(item => item.meetingNotes).flat();
            setNotes(allMeetingNotes)
        }
    }

    if(props.fieldState.invalid){
        message = props.errors[props.name]?.message;
        if(props.errors[props.name]?.message.includes("must be a `object` type")) {
            message = "Select a valid option";
        }
    }

    let markRequired = () => {
        return (
          <FontAwesomeIcon
            color='red'
            icon={faAsterisk}
            style={{ width: '7px',marginBottom:"5px",marginLeft:"2px" }}
          />
        );
    };

    let onSelect = (e) => {
        if (name == 'projectId') {
            props.setValue('taskId', '')
            props.setValue('sprint', '')
        }
        if (!props.getValues(props.item.name)) {
            props.setValue(props.item.name, e.value)
        }
        if (screen === "Tasks" && props.formType === 'add' && props.item && props.item.dependent) {
            if (e.value[props.item.dependent.key] == props.item.dependent.value && isDependencyRef.current) {
                props.handleFnEnableControlsBasedOnValue(props.item.dependent.field, true)
            } else {
                props.handleFnEnableControlsBasedOnValue(props.item.dependent.field, false)
            }
        }
    }

    const itemTemplate = (item) => {
        let name = item[props.item['searchField']];
        return (
            <div className="country-item">
                <div><span style={{ color: 'seagreen' }}>{item[props.templateField]}</span> - {name}</div>
            </div >
        );
    };

    return (
        <div className="flex flex-column">

            <div className="flex align-center"> {/* Added a wrapper div with flex class */}
                <label htmlFor={id} className="text-capitalize">{label}</label>
                {props.markReq && props.markReq === true && markRequired()}
            </div>
            <AutoComplete
                style={
                    screen === "Backlogs" ? { height: '40px' } :
                    screen === 'Active Sprints' && name === 'sprint' ? { width: '450px' } : {}
                }
                inputId={field.name}
                value={props.multiple && !Array.isArray(field.value) ? [] : field.value}
                onChange={onSelectRecord}
                // inputRef={field.ref}
                panelStyle={{ width: '100px' }}
                disabled={isDisable}
                suggestions={props.screensss == "feedback" ? assignedEmployees : (screen == 'Tasks' && props.name == "meetingDate") ? setNote : filteredSuggestions}
                completeMethod={getAllSuggestions}
                placeholder={field.value == undefined || field.value.length == 0 ? placeholder : ''}
                field={props.searchField}
                dropdown={true}
                onSelect={onSelect}
                multiple={props.multiple}
                selectionLimit={props.selectionLimit ? props.selectionLimit : config.selectionLimit}
                showEmptyMessage={true}
                itemTemplate={props.showItemTemplate ? itemTemplate : false}
            />

            <small className="text-danger ">{props.fieldState.invalid ? message ? message : props.errors[props.name]?.message : ''}</small>
            {errorMessage && <small className="text-danger">{errorMessage}</small>}
            {noData && !props.fieldState.invalid && <small className="text-danger ">
                {props.searchApi == 'tasks' ? 'No Tasks Found' : props.searchApi == 'projects' ? 'No Projects Assigned' : 'No Data Found'}
            </small>}
        </div>
    )

}

export default DoAutoCompleteField;