import React, { useEffect, useState, useRef } from "react";
import { Badge, Col, Row, Card, CardBody, CardHeader, Collapse, ButtonGroup, ButtonToolbar, Button } from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';
import fetchMethodRequest from "../../../../config/service";
import DoAutoCompleteField from "../../../Form/Fields/DoAutoCompleteField";
import KanbanBoard from "../../CommonModals/KanbanView";
import TaskDetailsDialog from "../../CommonModals/TaskDetailsDialog";

const ActiveSprints = () => {

  const [selectedProject, setSelectedProject] = useState({});
  const [isSelectSprint, setIsSelectSprint] = useState(true);
  const [showKanbanBoard, setShowKanbanBoard] = useState(false);
  const [saveTasks, setSaveTasks] = useState([]);
  const [saveSelectedSprintId, setSaveSelectedSprintId] = useState("");
  let [openTaskDetailsDialog, setOpenTaskDetailsDialog] = useState(false);
  let [visibleTaskDetails, setVisibleTaskDetails] = useState(false);
  let [neededTaskID, setNeededTaskID] = useState('');
  const [defaultSprint, setDefaultSprint] = useState({});

  const {
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
    control,
  } = useForm();

  useEffect (()=>{
    getDefaultSprint();
  },[])

  const getDefaultSprint = async () => {
    let filterCriteria = { limit: 20, page: 1, criteria: [], sortfield: 'created', direction: 'desc' };
    let url = `sprints?filter=${JSON.stringify(filterCriteria)}`;
    try {
      const response = await fetchMethodRequest('GET', url);
      if (response?.sprints) {
        const currentSprint = getCurrentSprint(response?.sprints);
        setDefaultSprint(currentSprint)
        setValue("projectId", currentSprint.projectId)
        setValue("sprint", [currentSprint])
        let data = {
          projectId : currentSprint.projectId,
          sprint: [currentSprint]
        }
        onClickSave(data);
      }
    } catch (err) {
      return err;
    }
  };


  const getCurrentSprint = (sprints) => {
    const currentDate = new Date();
    const validStatuses = ['In Progress', 'Not Started'];
    
    // First try to find a sprint with dates matching current date
    const exactMatch = sprints.find(sprint => {
      if (!validStatuses.includes(sprint.status)) {
        return false;
      }
      
      const startDate = new Date(sprint.startDate);
      const endDate = sprint.endDate ? new Date(sprint.endDate) : null;
      
      if (endDate) {
        endDate.setHours(23, 59, 59, 999);
        return currentDate >= startDate && currentDate <= endDate;
      }
      
      // For sprints with only start date, check if it's today
      return startDate.toDateString() === currentDate.toDateString();
    });
  
    if (exactMatch) {
      return exactMatch;
    }
  
    // If no exact match, find the sprint with the nearest start date
    const validSprints = sprints.filter(sprint => validStatuses.includes(sprint.status));
    
    if (validSprints.length === 0) {
      return null;
    }
  
    // Sort by closest start date to current date
    const sortedSprints = validSprints.sort((a, b) => {
      const dateA = new Date(a.startDate);
      const dateB = new Date(b.startDate);
      
      const diffA = Math.abs(currentDate - dateA);
      const diffB = Math.abs(currentDate - dateB);
      
      return diffA - diffB;
    });
  
    // Return the first sprint (closest to current date)
    return sortedSprints[0];
  };


  const feedbackFields = [
    {
      name: "projectId",
      fieldName: "projectId",
      type: "relateAutoComplete",
      value: defaultSprint?.projectId,
      placeholder: "Search Project First",
      label: " ",
      width: "150px",
      id: "projectId",
      required: true,
      searchField: "name",
      fieldType: "relateAutoComplete",
      displayFields: ["ProjectName"],
      controllerName: "Project",
      searchApi: "projects",
      filterCriteria: { key: 'status', value: 'Active', type: 'eq' },
      isMultiple: false
    },
  ];

  const projectSelected = { _id: selectedProject._id, name: selectedProject.name };

  const feedbackSprintsField = [
    {
      name: "sprint",
      fieldName: "sprint",
      type: "relateAutoComplete",
      value: defaultSprint,
      placeholder: "Select Sprint Name",
      label: " ",
      width: "150px",
      id: "sprint",
      required: false,
      searchField: "name",
      fieldType: "relateAutoComplete",
      displayFields: [],
      controllerName: "sprint",
      searchApi: "sprints",
      filterCriteria: { key: "projectId", value: !projectSelected?._id ? defaultSprint?.projectId : projectSelected , type: "in" },
      filterCriteria2: { key: 'status', value: ['Not Started', 'In Progress'], type: 'in' },
      isMultiple: true,
    },
  ];

  const onClickSave = async (formData, value) => { 
    setShowKanbanBoard(true)
    let sprintIds;

    if (formData && formData.sprint) {
      sprintIds = formData.sprint.map(sprint => sprint._id)
    } else {
      sprintIds = value
    }

    setSaveSelectedSprintId(sprintIds)

    let filterCriteria = { limit: 50, page: 1, criteria: [{ key: "sprint", value: sprintIds, type: "in" }], sortfield: 'created', direction: 'desc' };
    let url = `tasks?filter=${JSON.stringify(filterCriteria)}`;
    try {
      const response = await fetchMethodRequest('GET', url);
      if (response?.tasks) {
        setSaveTasks(response?.tasks)
      }
    } catch (err) {
      return err;
    }
  };

  return (
    <>
      <Card className='cardForListMargin' >
        <CardBody className='tableCardBody'>
          <div className='pb-2'>
            <div className='row m-1'>
              <div className='col-md-5 d-flex flex-wrap' style={{ alignItems: 'center' }}>
                <h4 style={{ paddingLeft: '6px' }}>
                  <span className='postionRelative pt-2'>
                    <a className="heading">Active Sprints</a>
                  </span>
                </h4>
              </div>
            </div>

            <div className='row mt-3'>
              <div className='col-md-12 d-flex'>
                <div className="mt-1 ms-2">
                  <div className="d-flex align-items-center flex-wrap">
                    {feedbackFields.map((item) => (
                      <div className="pb-2 me-3" key={item.name} style={{ flex: '1 1 auto' }}>
                        <Controller
                          name={item.name}
                          control={control}
                          render={({ field, fieldState }) =>
                            item.type === 'relateAutoComplete' && (
                              <DoAutoCompleteField
                                markReq={item.markReq}
                                input={field}
                                id={item.id}
                                name={field.name}
                                field={field}
                                filterField={item.filterField}
                                filterValue={item.filterValue}
                                filterType={item.isNotEq}
                                multiple={item.isMultiple}
                                fieldState={fieldState}
                                errors={errors}
                                searchApi={item.searchApi}
                                searchField={item.searchField}
                                criteria={item.filterCriteria || null}
                                filterFieldType={item.filterFieldType || null}
                                placeholder={item.placeholder}
                                label={item.label}
                                item={item}
                                setValue={setValue}
                                getValues={getValues}
                                screen={"Active Sprints"}
                                onChange={(value) => {
                                  setSelectedProject(value);
                                  setSaveTasks([])
                                }}
                              />
                            )
                          }
                        />
                      </div>
                    ))}

                    <form onSubmit={handleSubmit(onClickSave)} style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                      {feedbackSprintsField.map((item) => (
                        <div className="pb-2 me-3" key={item.name} style={{ flex: '1 1 auto' }}>
                          <Controller
                            name={item.name}
                            control={control}
                            render={({ field, fieldState }) =>
                              item.type === 'relateAutoComplete' && (
                                <DoAutoCompleteField
                                  markReq={item.markReq}
                                  input={field}
                                  id={item.id}
                                  name={field.name}
                                  field={field}
                                  filterField={item.filterField}
                                  filterValue={item.filterValue}
                                  filterType={item.isNotEq}
                                  multiple={item.isMultiple}
                                  fieldState={fieldState}
                                  errors={errors}
                                  searchApi={item.searchApi}
                                  searchField={item.searchField}
                                  criteria={item.filterCriteria || null}
                                  criteria2={item.filterCriteria2 || null}
                                  filterFieldType={item.filterFieldType || null}
                                  placeholder={item.placeholder}
                                  label={item.label}
                                  item={item}
                                  setValue={setValue}
                                  getValues={getValues}
                                  screen={"Active Sprints"}
                                  onChange={(value) => {
                                    if (value.name && (value.status === "Not Started" || value.status === "In Progress")) {
                                      setIsSelectSprint(false);
                                    }
                                    handleSubmit(onClickSave)();
                                  }}
                                />
                              )
                            }
                          />
                        </div>
                      ))}

                      {/* <div style={{ paddingTop: '10px' }}>
                        <Button type="submit" color="primary" disabled={isSelectSprint}>
                          Show Tasks
                        </Button>
                      </div> */}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div >

          {showKanbanBoard &&
            <div className='row' style={{ marginTop: '30px' }}>
              <div className='col-sm-12'>
                <KanbanBoard
                  taskDetails={saveTasks}  // total tasks
                  setOpenTaskDetailsDialog={setOpenTaskDetailsDialog} // to open the task edit modal
                  setVisibleTaskDetails={setVisibleTaskDetails} // visibility for task Id state
                  setNeededTaskID={setNeededTaskID} // trigger the state whe clicking on th task id 
                  sprintId={saveSelectedSprintId}  // selected sprint ID
                  onClickSave={onClickSave}
                />
              </div>
            </div>}

        </CardBody>
      </Card>
      {openTaskDetailsDialog && <TaskDetailsDialog
        openTaskDetailsDialog={openTaskDetailsDialog} // for opening the task dialog
        setOpenTaskDetailsDialog={setOpenTaskDetailsDialog} // opening the task dialog state
        neededTaskID={neededTaskID}
        setNeededTaskID={setNeededTaskID}
        visibleTaskDetails={visibleTaskDetails} // for the visibility when cicking the task Id in kanban view
        setVisibleTaskDetails={setVisibleTaskDetails} // visibility for task Id state
        // getActivities={getActivities} no need
        getDefaultSprint={getDefaultSprint}
        kanbanScreen={true}
        onClickSave={onClickSave}
        sprintId={saveSelectedSprintId}  
      />}
    </>
  );
};

export default ActiveSprints;
