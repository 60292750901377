import React, { useEffect, useState } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane, Spinner } from 'reactstrap';
import classNames from 'classnames';
import fetchMethodRequest from '../../../config/service';
import config from '../../../config/config';
import configImages from '../../../config/configImages';
import moment from 'moment';
import dateFormats from '../../UI/FormatDate/formatDate';

const ActivityComments = (props) => {
    let [activeTab, setActiveTab] = useState('1');
    let [getComments, setGetComments] = useState([]);
    let [loading, setLoading] = useState(false);
    let [historyData, setHistoryData] = useState([])

    useEffect(() => {
        getCommentsData();
        if (props.refreshRef) {
            props.refreshRef.current = getCommentsData;
          }
    }, [props.selectedId])

    let toggle = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    let getCommentsData = async () => {
        setLoading(true);
        fetchMethodRequest('GET', `tasks/${props.selectedData._id}?types=[COMMENTS,ATTACHMENTS]`)
            .then(async (response) => {
                if (response && response.comments) {
                    let comments = response.comments;
                    setGetComments(comments)
                    setHistoryData(response?.history)
                    setLoading(false);
                }
            }).catch((err) => {
                return err
            })
    }

    return (
        <div className="kanban__view__tabs">
            <div className="kanban__view__tabs__wrap">
                <Nav tabs className='kanban__view__nav__tabs' style={{ backgroundColor: 'white', borderColor: 'orange' }}>
                    <NavItem className='kanban__view__nav__item'>
                        <NavLink
                            className={classNames('kanban__view__nav__link history__custom__class', { active: activeTab === '1' })}
                            onClick={() => { toggle('1') }}>{'History'}</NavLink>
                    </NavItem>
                    <NavItem className='kanban__view__nav__item'>
                        <NavLink
                            className={classNames(' kanban__view__nav__link comments__custom__class', { active: activeTab === '2' })}
                            onClick={() => { toggle('2') }}>{'Comments'}</NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab} style={{ overflow: 'auto' }} className={activeTab === '1' ? 'history__tab__content' : 'comments__tab__content'}>
                    <TabPane className='kanban__view__tab__pane' tabId="1">
                        <div style={{ maxHeight: 400 }} className='p-datatable-wrapper'>
                            {(historyData && historyData.length === 0) ? (
                                <div className='kanban__view__spinner'>
                                    <Spinner color="dark" />
                                </div>
                            ) : (
                                historyData && historyData.length > 0 ? (
                                    historyData.map((item, i) => {
                                        return (
                                            <div key={i} className='row  p-2 m-0' >
                                                <div className="timeline__content__history col-sm-10">
                                                    <div className='row justify-content-between'>
                                                        <h5 className='col-sm-auto text-capitalize'>
                                                            {item.createdBy && item.createdBy.employee ?
                                                                item.createdBy.employee.displayName
                                                                : item.email}
                                                        </h5>
                                                        <h6 className='col-sm-auto'>{moment(item?.created).format('DD-MM-YYYY')}</h6>
                                                        <p className='col-12'
                                                            dangerouslySetInnerHTML={{ __html: item.description }}>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="timeline__profile_icon ms-3">
                                                    {item.createdBy?.employee?.photo.length > 0 ? (
                                                        <img
                                                            src={`${config.imgUrl}employee/${item.createdBy?.employee?.photo[0]}`}
                                                            // className="detailsImgStyle"
                                                            alt="Employee"
                                                        />
                                                    ) : (
                                                        <img
                                                            src={configImages.defaultImg}
                                                            className="defaultImgStyle"
                                                            alt="Default"
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        )
                                    })
                                ) : (
                                    <div style={{ textAlign: 'center', padding: '20px', color: '#666' }}>
                                        No History found
                                    </div>
                                )
                            )}
                        </div>
                    </TabPane>

                    <TabPane  className='kanban__view__tab__pane' tabId="2">
                        <div style={{ maxHeight: 600 }}>
                            {loading ? (
                                <div className='kanban__view__spinner'>
                                    <Spinner color="dark" />
                                </div>
                            ) : (
                                getComments && getComments.length > 0 ?
                                    getComments.map((item, i) => {
                                        return (
                                            <div key={i} className='row  p-2 m-0'>
                                                <div className="timeline__content__comments col-sm-10">
                                                    <div className='row justify-content-between' style={{ padding: '0px 10px 0px 10px' }}>
                                                        <h5 className='col-sm-auto text-capitalize'>{item.createdByName}</h5>
                                                        <h6 className='col-sm-auto'>{dateFormats.formatDate(item.created, config.dateDayMonthFormat)}</h6>
                                                        <p className='col-12 mt-1' dangerouslySetInnerHTML={{ __html: item.comments }}></p>
                                                    </div>
                                                </div>
                                                <div className="timeline__profile_icon ms-3">
                                                    {item.createdBy?.employee?.photo.length > 0 ? (
                                                        <img
                                                            src={`${config.imgUrl}employee/${item.createdBy?.employee?.photo[0]}`}
                                                            // className="detailsImgStyle"
                                                            alt="Employee"
                                                        />
                                                    ) : (
                                                        <img
                                                            src={configImages.defaultImg}
                                                            className="defaultImgStyle"
                                                            alt="Default"
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        )
                                    })
                                    : (
                                        <div style={{ textAlign: 'center', padding: '20px', color: '#666' }}>
                                            No Comments found
                                        </div>
                                    )
                            )}
                        </div>
                    </TabPane>
                </TabContent>
            </div>
        </div >
    )

}
export default ActivityComments;