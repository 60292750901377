import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import { NavLink } from 'react-router-dom';
//icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';


const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f17038',
    color: '#fff',
    boxShadow: theme.shadows[10],
    fontSize: 15,
    fontWeight: '500',
    margin: 0,
    textTransform: 'capitalize',
  },
}))(Tooltip);

//config
import config from '../../../config/config';
library.add(fas);
const SidebarLink = ({
  title, icon, newLink, route, onClick, iconType, isSubmenu, sidebarProps
}) => (
  <NavLink
    to={route}
    onClick={onClick}
    className={({ isActive }) => isActive ? "sidebar__link-active" : ""}
    activestyle={{ backgroundColor: config.darkTemplateColor, color: config.whiteColor }}
  >
    <div className="sidebar__link">
      {/* <span>
          <FontAwesomeIcon icon={["fa", 'user']} className="menu-icon" />
        </span> */}
         
      {(icon && (iconType === 'letters' || !iconType)) ?
        <span
          activeclassname={`active_sidebar__link-icon lnr `}
          className={`sidebar__link-icon lnr `} >{icon}</span>
        : ''}

      {sidebarProps.collapse ? ((icon && iconType === 'icon') ?
        <LightTooltip title={title} placement="right">
          <span>
            <FontAwesomeIcon icon={["fa", icon]} className="menu-icon" />
          </span>
        </LightTooltip>
        : null) :
        ((icon && iconType === 'icon') ?
          <span>
            <FontAwesomeIcon icon={["fa", icon]} className="menu-icon" />
          </span>
          : null)}

      <p className="sidebar__link-title" activeclassname='sidebar__link-active'
        style={isSubmenu && isSubmenu == true ? { paddingLeft: "18px" } : { paddingLeft: "8px" }}
      >

        {title}
        {newLink ? <Badge className="sidebar__link-badge"><span>New</span></Badge> : ''}
      </p>
    </div>
  </NavLink>
);

SidebarLink.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  newLink: PropTypes.bool,
  route: PropTypes.string,
  onClick: PropTypes.func,
  iconType: PropTypes.string
};

SidebarLink.defaultProps = {
  icon: '',
  newLink: false,
  route: '/',
  onClick: () => { },
};

export default SidebarLink;