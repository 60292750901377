import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';


// config file
const DeleteRowModal = (props) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    

    const deleteSelectedRow = () => {
        setIsSubmitting(true)
        props.deleteSelectedRow();
    }

  
        return (
            <div>
                <Modal isOpen={props.openDeleteModal}
                    className='modal-dialog-centered modal-dialog--primary m-auto logout_modal_width'
                >
                    <ModalBody className='deleteModalBody'>
                        <div style={{ marginBottom: 10 }}>Are you sure want to Delete?</div>
                        <Button color="primary" outline onClick={props.closeDeleteModal} className='deleteModalBtn marginRight'>No</Button>
                        <Button color="primary" outline onClick={deleteSelectedRow} disabled={isSubmitting}
                            className='deleteModalBtn'>Yes</Button>
                    </ModalBody>
                </Modal>
            </div>
        );
    
}


export default DeleteRowModal;